import React, { Component } from "react";
import style from "./header.module.css";
import logo from "../../Assets//logo.jpg";
import BadgeAvatars from "../../Neet-Components/Header_Avatar/BadgeAvatars";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HeaderMenuButton from "../../Neet-Components/Buttons/HeaderMenuButtons";
import { connect } from "react-redux";
import { getBaseUrlForLogo } from "../../Handler/globalHandler";
import SettingImg from "../../Assets/icons/setting.png";
import LogOutImg from "../../Assets/icons/logout.png";
import LockImg from "../../Assets/icons/lock.png";

interface HeaderState {
  isShowMenuList: boolean;
  loading:boolean;
}

enum screenTypeSettings {
  ACCOUNT = "account",
  PAYMENT = "payment",
  PASSWORD = "password",
}

interface HeaderProps {
  onLogoButtonClicked: Function;
  onSettingButtonClicked: Function;
  onHeaderChangePasswordButtionClicked: Function;
  organization: any;
  customer: any;
  screenType: string;
}

export class Header extends Component<HeaderProps, HeaderState> {
  private headerRef = React.createRef<HTMLDivElement>();

  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      isShowMenuList: false,
      loading:false,
    };
  }

  componentDidMount(): void {
    document.addEventListener("click", this.handleClickOutside, true);
    // console.log(this.props.organization, this.props.customer);
  }

  componentWillUnmount(): void {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  onMenuButtonClicked = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    this.setState({ isShowMenuList: !this.state.isShowMenuList });
  };

  handleClickOutside = (event: MouseEvent) => {
    const headerNode = this.headerRef.current;
    if (headerNode && !headerNode.contains(event.target as Node)) {
      this.setState({ isShowMenuList: false });
    }
  };

  render() {
    const { isShowMenuList } = this.state;
    const {
      onLogoButtonClicked,
      onSettingButtonClicked,
      onHeaderChangePasswordButtionClicked,
      customer,
      organization,
    } = this.props;

    return (
      <div className={style.header} ref={this.headerRef}>
        <img
          src={
            organization?.logo
              ? getBaseUrlForLogo() + "/image/" + organization?.logo
              : logo
          }
          className={style.header_logo}
          onClick={() => {
            onLogoButtonClicked();
          }}
        />
        <div
          className={style.header_containt}
          onClick={this.onMenuButtonClicked}
        >
          <BadgeAvatars
            firstLaterOfName={
               "M"
            }
          />
          <div className={style.header_user_container}>
            <p className={style.header_username}>
              {customer ? customer?.firstName?.slice(0, 8)+" "+customer?.lastName?.slice(0, 8):"user name" }                                
            </p>
          </div>
          <div>
            {!isShowMenuList ? (
              <KeyboardArrowDownIcon
                style={{ cursor: "pointer", color: "rgba(127, 125, 131, 1)" }}
              />
            ) : (
              <KeyboardArrowUpIcon
                style={{ cursor: "pointer", color: "rgba(127, 125, 131, 1)" }}
              />
            )}
          </div>
        </div>
        {isShowMenuList && (
          <div className={style.header_menu_list}>
            <div
              style={{
                width: "100%",
                padding: "0px 0px 7px 0px",
                borderBottom: "1px solid #EFF0F2",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <p className={style.header_username}>
                {customer ?customer?.firstName?.slice(0, 8):"username"}   {" "}{customer ?customer?.lastName?.slice(0, 8):""}
              </p>
              <p className={style.header_useremail}>
                {customer ? customer?.email?.slice(0, 25):"useremail@gmail.com"}
              </p>
            </div>
          {!customer?.isGuestUser && <div
              style={{
                width: "100%",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <HeaderMenuButton
                buttonName={"Settings"}
                onClick={() => {
                  onSettingButtonClicked();
                  this.setState({ isShowMenuList: false });
                }}
                buttonLoading={false}
                iconName={<img src={SettingImg} style={{ height: "20px" }} />}
                isSelected={
                  this.props.screenType === screenTypeSettings.PAYMENT
                }
              />
            </div>}  
            {!customer?.isGuestUser &&
            <div
              style={{
                width: "100%",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* this is not work until you can not but a condition right away on the way of this. */}
              
              <HeaderMenuButton
                buttonName={"Change Password"}
                onClick={() => {
                  onHeaderChangePasswordButtionClicked();
                  this.setState({ isShowMenuList: false });
                }}
                buttonLoading={false}
                iconName={<img src={LockImg} style={{ height: "20px" }} />}
                isSelected={
                  this.props.screenType === screenTypeSettings.PASSWORD
                }
              />
            </div>}
            <div
              style={{
                width: "100%",
                padding: "4px 0px 0px 0px",
                borderTop:!customer?.isGuestUser ? "1px solid #EFF0F2":"",
              }}
            >
              <HeaderMenuButton
                buttonName={"Logout"}
                onClick={() => {
                  this.setState({loading:true})
                  console.log('logout button pressed')
              localStorage.removeItem("shivaayAdmin");
              this.setState({loading:false})
              window.location.href="/login";        

                }}
                buttonLoading={this.state.loading}
                iconName={<img src={LogOutImg} style={{ height: "20px" }} />}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
