export const  validateObject=(obj:any) =>{
  console.log(obj)
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === "string" && value.trim() === "") {
            return key; // Return the name of the key with an empty string value
          }
        if (typeof value === "number" && isNaN(value)) {
            return key; // Return the name of the key with a NaN value
        }
        if(key==="email"){
          const validEmail=validateEmail(value);
          if(!validEmail){
            return key;
          }
        }
      }
    }
    return "right"; // Return true if all keys have non-empty values
  }
export const LogOutUserWithOrgAlias = (orgAlias:string) => {
      localStorage.removeItem("_live_zaiper_user_details");
      document.cookie =
        "_live_zaiper_user_token" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      setTimeout(() => {
        window.location.href = `/customer/${orgAlias ? orgAlias :""}`;
      }, 200);
    }
  export const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  export const setDataToLocalStorageByKeyName = (keyName:string,localData: any) => {
    localStorage.setItem(keyName, JSON.stringify(localData));
    return true;
  };
  
  export const getDetailsFromLocal = (keyName:string) => {
    const retrieveData:any = localStorage.getItem(keyName);
    if(retrieveData){
      const adminData = JSON.parse(retrieveData);
      if (adminData) {
        return adminData;
      }
      else{
          return undefined;
      }
    }
    else{
      return undefined;
    }
    
  };
  
  const getCookie = (n: any) => {
    let a = `; ${document.cookie}`.match(`;\\s*${n}=([^;]+)`);
    return a ? a[1] : "";
  };

  export const getUserToken = () => {
    const token = getCookie("_live_zaiper_user_token");
    if (token) {
      return token;
    } else {
      return undefined;
    }
  };

  export const  isValidPassword=(password:string) =>{
    // Define regex patterns for each requirement
    const lengthPattern = /.{8,}/;
    const uppercasePattern = /[A-Z]/;
    const lowercasePattern = /[a-z]/;
    const numberPattern = /\d/;
    // const symbolPattern = /[^A-Za-z0-9]/;
  
    // Check if the password matches all the patterns
    const hasValidLength = lengthPattern.test(password);
    const hasUppercase = uppercasePattern.test(password);
    const hasLowercase = lowercasePattern.test(password);
    const hasNumber = numberPattern.test(password);
    // const hasSymbol = symbolPattern.test(password);
  
    // Return true if all conditions are met
    return hasValidLength && hasUppercase && hasLowercase && hasNumber ;
  }
  