import { Button, CircularProgress } from "@mui/material";
import React, { Component } from "react";
import errorImage from "../../Assets/errorImage.jpg";
import "./errorPage.css";
import { useNavigate } from "react-router-dom";
// import CurcularLoader from "../../small-component/loader/CurcularLoader";
// import { getAgentToken } from "../../handler/GlobalFunctions";

interface ErrorPageProps {
  // navigate: any;
}
interface ErrorPageState {
  loading: boolean;
}
export class ErrorPageWrap extends Component<ErrorPageProps, ErrorPageState> {
  constructor(props: ErrorPageProps) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  onRefreshPageButtonPress = () => {
    // this.props.navigate("/");
  };
  componentDidMount(): void {
    // const agentToken = getAgentToken();
    // if (agentToken !== undefined) {
    //   setTimeout(() => {
    //     this.props.navigate("/history");
    //   }, 200);
    // } else {
    //   this.setState({ loading: false });
    // }
  }
  render() {
    return this.state.loading ? (
      <CircularProgress />
    ) : (
      <div className="error-page">
        <div className="error-container">
          <div className="button-text-container">
            <div className="heading">
              <h1 className="head">
                <span>Url Not Valid ! </span>This is a invalid url ! Please
                Contact with your url provider for more information.
              </h1>
            </div>
            <div className="button-container">
              <Button
                variant="contained"
                className="button"
                size="large"
                onClick={this.onRefreshPageButtonPress}
              >
                Contact with owner
              </Button>
            </div>
          </div>
          <div className="error-image-container">
            <img alt="error-image" src={errorImage} />
          </div>
        </div>
      </div>
    );
  }
}

const ErrorPage = () => {
  return (<ErrorPageWrap/>);
};
export default ErrorPage;
