import React, { Component } from "react";
import style from "./addCategorys.module.css";
import { connect } from "react-redux";

interface AddCategoryState {
}
interface AddCategoryProps {  
}
export  class AddCategory extends Component<AddCategoryProps, AddCategoryState> {
  constructor(props: AddCategoryProps) {
    super(props);
    this.state = {      
    };
  }

  componentDidMount(): void {
  }    
  render() {    
    const {  } = this.props;
    const {  } = this.state;
    return (
      <div>
        Add Hotesl
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({  
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCategory);