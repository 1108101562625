import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ApiClient from "../../Api/apiClient";
import { Close } from "@mui/icons-material";

export default function Update_Password() {
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = useState({
        employeeId: "",
        password: "",
        confirmPassword: "",
    });

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const apiData = {
            employeeId: event.target.employeeId.value,
            password: event.target.password.value,
            confirmPassword: event.target.confirmPassword.value,
        };
        if(!apiData.employeeId){
            alert("Emp id must be provided")
            return;
        }
        if(!apiData.password || apiData.password.length<4){
            alert("Password Mush be provided and leght should be greater then 5");
            return;
        }
        if(!apiData.confirmPassword || apiData.confirmPassword!=apiData.password){
            alert("Password Did't Match Confirm Password");
            return;
        }
        setLoading(true);                
        try {
            const response = await ApiClient.getInstance().post(
                "/api/employee/updatePassword",
                apiData
            );

            console.log(response);

            if (response.data.statusCode === 200) {
                alert("Password updated successfully");
                // Reset form data after successful API call
                setFormData({
                    employeeId: "",
                    password: "",
                    confirmPassword: "",
                });

                // Optionally, you can handle success actions here
            } 
            if(response.data.statusCode==402){
                alert(`Employee Not Found With Id ${apiData.employeeId}`);
                // Reset form data after successful API call                
            }
            else {
                console.log(response);
                alert(response.data.message || "Unknown error");
            }
            setLoading(false);
        } catch (error: any) {
            console.error("Error during API call:", error);
            alert(error.message || "Unknown error");
            setLoading(false);
        }
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    return (
        <Container component="main"  style={{ background: "#fff", borderRadius: "10px",width:"100%",height:"100%" ,paddingTop:'20px'}}>
            {/* <div style={{ width: "100%", display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
        <div onClick={onCloseEditModelClicked} style={{ marginTop: "20px", padding: "10px", cursor: "pointer", background: "lightgray", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Close />
        </div>
      </div>
      <CssBaseline /> */}
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <PersonIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Edit Employee
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="employeeId"
                                label="Employee ID"
                                name="employeeId"
                                onChange={handleChange}
                                value={formData.employeeId}                                
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="password"
                                required
                                fullWidth
                                id="password"
                                label="Password"
                                autoFocus
                                onChange={handleChange}
                                value={formData.password}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="confirmPassword"
                                required
                                fullWidth
                                id="confirmPassword"
                                label="Confirm Password"
                                autoFocus
                                onChange={handleChange}
                                value={formData.confirmPassword}
                            />
                        </Grid>
                    </Grid>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {loading ? "Loading..." : "Update Password"}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}
