import React, { useState } from "react";
import style from "./textFields.module.css";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

const TextFieldPassword = ({
  onChange,
  placeholder,
  defaultValue,
  value,
  errorMsg,
  icon,
  handleKeyPress
}: any) => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const onPasswordIconClicked = () => {
    setIsShowPassword(!isShowPassword);
  };
  return (
    <div className={style.text_field_password_container}>
      <input
        className={style.text_field_password}
        placeholder={placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        type={isShowPassword || icon? "text" : "password"}
        onKeyDown={handleKeyPress?handleKeyPress:()=>{}}
      />
      {/* {!isShowPassword ? ( */}
      {icon? icon :  <VisibilityOutlined
          className={style.text_field_icon}
          onClick={onPasswordIconClicked}
          sx={isShowPassword?{color:"#006BFF"}:{}}
        />}
      {/* ) : (
        <VisibilityOffOutlined
          className={style.text_field_icon}
          onClick={onPasswordIconClicked}
        />
      )} */}
      <p
        className={style.text_field_password_error}
        style={
          errorMsg.length > 1
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
      >
        {errorMsg.length > 1 ? errorMsg : "A"}
      </p>
    </div>
  );
};

export default TextFieldPassword;
