import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCookies } from "react-cookie";
import { setDataToLocalStorageByKeyName } from '../Globals/Functions';

export const HighOrderFunctionWithParams = ({Component}:any) => {
    const params=useParams();
    const [cookies, setCookie] = useCookies(["customer"]);
    const navigation=useNavigate();
    useEffect(()=>{
      setDataToLocalStorageByKeyName("_live_zaiper_customer_queue_id",{queueId:params.queueId})
    },[])
  return (
    <Component params={params} navigation={navigation} setCookie={setCookie}/>
  )
}

export const HighOrderFunction = ({Component}:any) => {
    const navigation=useNavigate();
  return (
    <Component  navigation={navigation}/>
  )
}



