import React, { Component } from "react";
import style from "./callLogsLayout.module.css";
import { Avatar, Button } from "@mui/material";
import { connect } from "react-redux";
import { PunchRecord } from "../../Components/Call_Logs/CallLogs";
import Loading_Page from "../../Neet-Components/Loading-Page/Loading_Page";
import moment from "moment";

interface CallLogsLayoutProps {
  attendaceRecords: PunchRecord;
  empData: any;
  onEditButtonPressed: Function;
}
interface CallLogsLayoutState {
  loading: string;
  matchedEmployee: any;
}
export class CallLogsLayout extends Component<
  CallLogsLayoutProps,
  CallLogsLayoutState
> {
  canJoinPaidQueue?: boolean;
  constructor(props: CallLogsLayoutProps) {
    super(props);
    this.state = {
      loading: "pageLoading",
      matchedEmployee: null,
    };
  }
   timestampToTime = (timestamp:any) => {
    const time = new Date(parseInt(timestamp));
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    const formattedHours = hours % 12 || 12;

    const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;

    console.log(timestamp);
    return formattedTime;
}
  componentDidMount(): void {
    const matchedEmp = this.props.empData.filter((data: any) => {
      if (this.props.attendaceRecords?.employeeId) {
        return data._id == this.props.attendaceRecords?.employeeId;
      }
      else {
        return data.employeeId == this.props.attendaceRecords?.employeeId;
      }
    });
    console.log(this.props.empData)
    console.log(matchedEmp);
    this.setState({ matchedEmployee: matchedEmp, loading: '' });
    console.log(matchedEmp);
  }
  render() {
    const { loading } = this.state;
    const { attendaceRecords, empData } = this.props;
    if (loading == 'pageLoading') {
      return <Loading_Page />
    }
    if (this.state.matchedEmployee.length < 1) {
      return "";
    }
    return (
      <div className={style.CallLogsLayout} style={{ height: "auto", paddingTop: "15px", flexDirection: "column", width: "500px", gap: "20px", padding: "30px 5px" }}>
        <div className={style.CallLogsLayout_avatar} style={{ justifyContent: "center", width: "100%" }}>
          <Avatar style={{ background: "red" }} />
          {/* <p className={style.queue_status_online}>Caller Img</p> */}
          <div className={style.CallLogsLayout_details}>
            <p className={style.CallLogsLayout_header}>EmpId : {this.state.matchedEmployee[0]?.employeeId}</p>
            <p className={style.CallLogsLayout_title} style={{ fontSize: "17px" }}>
              {this.state.matchedEmployee[0].firstName + " " + this.state.matchedEmployee[0].lastName}
            </p>
          </div>
        </div>
        <div className={style.CallLogsLayout_timers} style={{ display: "grid", gridTemplateColumns: "140px 1fr" }}>
          <div className={style.CallLogsLayout_stats}>
            <p className={style.CallLogsLayout_stats_heading} style={{ fontWeight: "700" }}>
              Punch In Time
            </p>
            <p className={style.CallLogsLayout_stats_heading}>
              {attendaceRecords.punchInTime ? this.timestampToTime(attendaceRecords.punchInTime) : 'null'}
            </p>
          </div>
          <div className={style.CallLogsLayout_stats}>
            <p className={style.CallLogsLayout_stats_heading} style={{ fontWeight: "700" }}>
              Punch In Location
            </p>
            <p className={style.CallLogsLayout_stats_heading}>
              {typeof (attendaceRecords.punchInLocation) == "string" ? attendaceRecords.punchInLocation ? attendaceRecords.punchInLocation : "--" : 'null'}
            </p>
          </div>
          <div className={style.CallLogsLayout_stats}>
            <p className={style.CallLogsLayout_stats_heading} style={{ fontWeight: "700" }}>
              Punch Out Time
            </p>
            <p className={style.CallLogsLayout_stats_heading}>
              {attendaceRecords.punchOutTime ? this.timestampToTime(attendaceRecords.punchOutTime) : 'null'}
            </p>
          </div>
          <div className={style.CallLogsLayout_stats}>
            <p className={style.CallLogsLayout_stats_heading} style={{ fontWeight: "700" }}>
              Punch Out Location
            </p>
            <p className={style.CallLogsLayout_stats_heading}>
              {typeof (attendaceRecords.punchOutLocation) == "string" ? attendaceRecords.punchOutLocation ? attendaceRecords.punchOutLocation : "--" : 'null'}
            </p>
          </div>
        </div>

        <div className={style.CallLogsLayout_stats}>
          <p className={style.CallLogsLayout_stats_heading} style={{ fontWeight: "700" }}>
            Status
          </p>
          {attendaceRecords.isHoliday ? <p className={style.CallLogsLayout_stats_heading} style={{ color: "black" }}>
            Holiday
          </p>:
          <>
          {attendaceRecords.isLeaveApproved && <p className={style.CallLogsLayout_stats_heading} style={{ color: "red" }}>
            On Leave
          </p>}
          {!attendaceRecords.isLeaveApproved && !attendaceRecords.isLeaveDenied && (attendaceRecords.isRequestForLeave && <p className={style.CallLogsLayout_stats_heading} style={{ color: "yellow" }}>
            Waiting for Approval
          </p>)}
          {!attendaceRecords.isRequestForLeave && !attendaceRecords.isWeekend && !attendaceRecords.isHoliday && (attendaceRecords.punchInTime && <p className={style.CallLogsLayout_stats_heading} style={{ color: "green" }}>
            Present
          </p>)}
          {!attendaceRecords.isLeaveApproved && (!attendaceRecords.isRequestForLeave && (attendaceRecords.punchInTime === "" && <p className={style.CallLogsLayout_stats_heading} style={{ color: "skyblue" }}>
            Pending...
          </p>))}          
          </>
          }
        </div>
        {/* <div className={style.CallLogsLayout_stats}> */}
          {/* <ButtonWithIcon background={'green'} buttonN ame={"Edit"} onClick={() => { this.props.onEditButtonPressed(attendaceRecords) }} /> */}
        {/* </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallLogsLayout);
