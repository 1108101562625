import { CircularProgress } from '@mui/material'
import React, { Component } from 'react'
import style from "./loading_page.module.css"

export default class Loading_Page extends Component {
  render() {
    return (
      <div className={style.loading_page}>
        <CircularProgress />
      </div>
    )
  }
}


// sx={{color:"#006BFF!important",height:"50px!important",width:"50p!importantx"}}