import React, { Component } from "react";
import style from "./change_password.module.css";
import TextFieldPassword from "../../Neet-Components/Text-Fields/TextFieldPassword";
import ButtonWithBackground from "../../Neet-Components/Buttons/ButtonWithBackground";
import ButtonWithIcon from "../../Neet-Components/Buttons/ButtonWithIcon";
import {validateObject } from "../../Globals/Functions";
import { connect } from "react-redux";
import ApiClient from "../../Api/apiClient";

interface Change_PasswordState {
  oldPassword: string;
  newPassword: string;
  reTypePassword: string;
  loading: string;
  error: string;
  adminDetails:any;
}
interface Change_PasswordProps {
  onChangePasswordBackButtonClicked: Function;
}
export class Change_Password extends Component<
  Change_PasswordProps,
  Change_PasswordState
> {
  constructor(props: Change_PasswordProps) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      reTypePassword: "",
      loading: "",
      error: "",
      adminDetails:{},
    };
  }
  componentDidMount(): void {
    const data: any = localStorage.getItem("shivaayAdmin");
    const user = JSON.parse(data);    
    this.setState({adminDetails: user })
  }
  onUpdatePasswordButtonClicked = async () => {
    this.setState({ loading: "updateButton" });
    const validateValues = validateObject({
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword,
      reTypePassword: this.state.reTypePassword,
    });
    const matchPassword = this.state.newPassword === this.state.reTypePassword;
    if (validateValues === "right") {
      // if (!isValidPassword(this.state.newPassword)) {
      //   this.setState({ error: "invalidPassword", loading: "" });
      //   return;
      // }
      if (!matchPassword) {
        this.setState({ error: "confirmPassword", loading: "" });
        return;
      }
      const res:any = await ApiClient.getInstance().post("api/admin/changePassword",{_id:this.state.adminDetails._id,newPassword:this.state.newPassword,oldPassword:this.state.oldPassword})
      console.log(res);
      if (res.data.statusCode === 200) {
        alert("password updated successfully");
        this.props.onChangePasswordBackButtonClicked();        
        this.setState({ loading: "" });
      } else if (res.data.statusCode === 401) {
        this.setState({ error: "wrongOldPassword", loading: "" });
      } else {
        this.setState({ error: "api", loading: "" });
      }
    } else {
      this.setState({ error: validateValues, loading: "" });
    }
  };
  render() {
    const { onChangePasswordBackButtonClicked } = this.props;
    const { loading, error } = this.state;
    return (
      <div className={style.change_password}>
        <p className={style.textfield_label}>Old Password</p>
        <TextFieldPassword
          placeholder={"Password"}
          errorMsg={
            error === "oldPassword"
              ? "Please enter valid details."
              : error === "wrongOldPassword"
              ? "Invalid old password."
              : ""
          }
          onChange={(e: any) => {
            this.setState({ oldPassword: e.target.value });
          }}
        />
        <p className={style.textfield_label}>New Password</p>
        <TextFieldPassword
          placeholder={"Password"}
          errorMsg={
            error === "newPassword"
              ? "Please enter valid details."
              : error === "invalidPassword"
              ? "Password should be contain a minimum of 8 characters 1 uppercase and 1 lowercase"
              : ""
          }
          onChange={(e: any) => {
            this.setState({ newPassword: e.target.value });
          }}
        />
        <p className={style.textfield_label}>Re-type New Password</p>
        <TextFieldPassword
          placeholder={"Password"}
          errorMsg={
            error === "reTypePassword"
              ? "Please enter valid details."
              : error === "confirmPassword"
              ? "Password does not match."
              : ""
          }
          onChange={(e: any) => {
            this.setState({ reTypePassword: e.target.value });
          }}
        />
        <div className={style.buttons}>
          <ButtonWithIcon
            buttonName={"Cancel"}
            onClick={() => {
              onChangePasswordBackButtonClicked();
            }}
            buttonLoading={false}
            background={"#fff"}
            color={"black"}
          />
          <ButtonWithBackground
            buttonName="Update"
            onClick={() => {
              this.onUpdatePasswordButtonClicked();
            }}
            buttonLoading={loading === "updateButton"}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Change_Password);
