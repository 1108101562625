import React, { Component } from "react";
import style from "./account_details.module.css";
import ButtonWithBackground from "../../Neet-Components/Buttons/ButtonWithBackground";
import TextFieldSimple from "../../Neet-Components/Text-Fields/TextFieldSimple";
import { Skeleton } from "@mui/material";
import { connect } from "react-redux";
import {
  editCustomerDetailsApi,
} from "./apiHandler";
import { setDataToLocalStorageByKeyName, validateObject } from "../../Globals/Functions";
import ApiClient from "../../Api/apiClient";

interface CustomerAccountDetails {
  firstName: string;
  lastName: string;
  companyName: string;
  _id: string;
}
interface Account_DetailsProps {
  customer: any;
  setCustomerDetails:Function;
}
interface Account_DetailsState {
  
  loading: string;
  customerAccountDetails: CustomerAccountDetails;
  error: string;
  adminDetails:any;
}
export class Account_Details extends Component<
  Account_DetailsProps,
  Account_DetailsState
> {
  constructor(props: Account_DetailsProps) {
    super(props);
    this.state = {
      loading: "",
      customerAccountDetails: {
        firstName: this.props.customer.firstName,
        lastName: this.props.customer.lastName,
        companyName: this.props.customer.companyName,
        _id: this.props.customer._id,
      },
      error: "",
      adminDetails:{}
    };
  }  
  componentDidMount(): void {
    this.setState({loading:'pageLoading'})
    const data: any = localStorage.getItem("shivaayAdmin");
    const user = JSON.parse(data);    
    console.log(user)
    this.setState({adminDetails: user })
    this.setState({loading:''})
  }
  onSaveChangesButtonClicked = async () => {
    this.setState({ loading: "saveButtonLoading" });        
    console.log(this.state.adminDetails);
    const res=await ApiClient.getInstance().post('/api/admin/update',this.state.adminDetails)
    console.log(res);
    if(res.data.isSuccess){
      this.props.setCustomerDetails(res.data.data);
      setDataToLocalStorageByKeyName("shivaayAdmin",res.data.data);
      this.setState({loading:"",error:""})
      alert("Details updated successfully")
    }
    else{
      this.setState({loading:"",error:""})
      alert("something went wrong while updating.")
    }
  };
  render() {
    const {
      loading,
      
      customerAccountDetails,
      
      error,
      adminDetails
    } = this.state;
    return (
      <div className={style.account_details}>
        <div className={style.account_details_container}>
          <div>
            <p className={style.textfield_label}>First Name</p>
            {loading === "pageLoading" ? (
              <Skeleton height={"50px"} />
            ) : (
              <TextFieldSimple
              value={adminDetails.firstName}
                placeholder={"First Name"}
                errorMsg={
                  error === "firstName" ? "Plase enter valid details." : ""
                }
                defaultValue={adminDetails.firstName}
                onChange={(e: any) => {
                  this.setState({
                    adminDetails: {
                      ...adminDetails,
                      ["firstName"]: e.target.value,
                    },
                  });
                }}
              />
            )}
          </div>
          <div>
            <p className={style.textfield_label}>Last Name</p>
            {loading === "pageLoading" ? (
              <Skeleton height={"50px"} />
            ) : (
              <TextFieldSimple
              value={adminDetails.lastName}
                placeholder={"Last Name"}
                errorMsg={
                  error === "lastName" ? "Plase enter valid details." : ""
                }                
                defaultValue={adminDetails.lastName}
                onChange={(e: any) => {
                  this.setState({
                    adminDetails: {
                      ...adminDetails,
                      ["lastName"]: e.target.value,
                    },
                  });
                }}
              />
            )}
          </div>
          <div>
            <p className={style.textfield_label}>Company Name</p>
            {loading === "pageLoading" ? (
              <Skeleton height={"50px"} />
            ) : (
              <TextFieldSimple
              value={adminDetails.companyName}
                placeholder={"Company Name"}
                errorMsg={
                  error === "companyName" ? "Plase enter valid details." : ""
                }
                defaultValue={adminDetails.companyName}
                onChange={(e: any) => {
                  this.setState({
                    adminDetails: {
                      ...adminDetails,
                      ["companyName"]: e.target.value,
                    },
                  });
                }}
              />
            )}
          </div>          
        </div>

        <div className={style.buttons}>
          <ButtonWithBackground
            buttonName="Save Changes"
            onClick={() => {
              this.onSaveChangesButtonClicked();
            }}
            buttonLoading={loading === "saveButtonLoading"}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  
});

const mapDispatchToProps = {
  
};

export default connect(mapStateToProps, mapDispatchToProps)(Account_Details);
