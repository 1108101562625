import React from "react";
import style from "./button.module.css";
import { CircularProgress } from "@mui/material";

const ButtonWithIcon = ({ buttonName, onClick, buttonLoading,background,iconName ,color,isSelected}: any) => {
  return (
    <button onClick={onClick} className={isSelected ? style.button_with_icon:style.button_with_icon} style={{background: background,color:color}}>
    {iconName && iconName}
      {buttonLoading ? (
        <CircularProgress
          sx={{
            color: color,
            height: "20px!important",
            width: "20px!important",
          }}
        />
      ) : (
        buttonName
      )}
    </button>
  );
};

export default ButtonWithIcon;
