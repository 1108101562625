import React, { useEffect, useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Container from "@mui/material/Container";
import ApiClient from "../../Api/apiClient";
import { Close } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import moment from "moment";

export default function EditAttandance({ attandanceToEdit, onCloseEditModelClicked }: any) {
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = useState({    
    _id:attandanceToEdit._id,
    employeeId: attandanceToEdit.employeeId,        
    delayTime: attandanceToEdit.delayTime,    
    isOnLeaves: attandanceToEdit.isOnLeaves,
    punchInTime:attandanceToEdit.punchInTime,
    punchOutTime:attandanceToEdit.punchOutTime,
    isRequestForLeave: attandanceToEdit.isRequestForLeave,    
    isLeaveApproved: attandanceToEdit.isLeaveApproved,
    isLeaveDenied: attandanceToEdit.isLeaveDenied,
    isHoliday: attandanceToEdit.isHoliday,        
    isWeekend: attandanceToEdit.isWeekend,
    overTime: attandanceToEdit.overTime,
  });
  const timestampToTime=(timestamp:any)=>{
    const time=new Date(parseInt(timestamp));
    console.log(timestamp);
    return time.getHours()+":"+time.getMinutes();  
  }
  const [timeSlot,setTimeSlot]=useState({punchInTime:timestampToTime(attandanceToEdit.punchInTime),punchOutTime:timestampToTime(attandanceToEdit.punchOutTime)})
  useEffect(()=>{
    console.log(attandanceToEdit)
  })  
  const removeFirstZero=(value:any)=>{
    const stringValue=value.toString();
    if(stringValue.length>1){
      if(stringValue.slice(0,1)=="0"){
        return parseInt(stringValue.slice(1,stringValue.length))
      }
      else{
        return parseInt(stringValue);
      }
    }
    else{
      return parseInt(stringValue);
    }
  }
  const addNewTime=(originalTimestamp:any,istTimeString:any)=>{    
    console.log("originalTimestamp",originalTimestamp)
    console.log("istTimeString",istTimeString)
    const originalDateArray = originalTimestamp.slice(0,10).split("-");    
    console.log("originalDateArray",originalDateArray);
    const updatedTime = istTimeString.split(":");        
    const updatedDate=new Date(removeFirstZero(originalDateArray[0]),removeFirstZero(originalDateArray[1])-1,removeFirstZero(originalDateArray[2]),removeFirstZero(updatedTime[0]),removeFirstZero(updatedTime[0]),0,0);
    const modifiedTimestamp = updatedDate.getTime();
    console.log("updatedDate",modifiedTimestamp);
    return modifiedTimestamp;
  }
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);               
    // const punchIndate=moment(+attandanceToEdit.punchInTime).format('MM/DD/YYYY');
    const createdTime=new Date(attandanceToEdit.createdAt);
    formData.punchInTime= addNewTime(attandanceToEdit.createdAt,timeSlot.punchInTime);   
    // const punchOutdate=moment(+attandanceToEdit.punchOutTime).format('MM/DD/YYYY');
    formData.punchOutTime= addNewTime(attandanceToEdit.createdAt,timeSlot.punchOutTime);      
    console.log(formData.punchInTime,"new punchInTime");    
    console.log(formData.punchOutTime,"new punchOutTime");    
    try {
      const response = await ApiClient.getInstance().post(
        "/api/attandance/update",
        formData
      );
      console.log(response);
      if (response.data.statusCode === 200) {
        alert("Employee updated successfully");
        onCloseEditModelClicked(true);        
      } else {
        alert(response.data.message || "Unknown error");
      }
      setLoading(false);
    } catch (error: any) {
      console.error("Error during API call:", error);
      alert(error.message || "Unknown error");
      setLoading(false);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {    
    const { name, value } = event.target;
    if(name=="delayTime" || name=="overTime"){
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    else{
      let newValue:any;
      if(value=="0"){
        newValue=false;
      }
      else if (value=="1"){
        newValue=true;
      }
      else{
        newValue=value;
      }
      setFormData((prevData) => ({
        ...prevData,
        [name]: newValue,
      }));
    }
    console.log(name, value)
  };
  const handleChangeForTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTimeSlot((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(name, value);
  }
  return (
    <Container component="main" maxWidth="xs" style={{ background: "#fff", borderRadius: "10px", margin: "20px" }}>
      <div style={{ width: "100%", display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
        <div onClick={onCloseEditModelClicked} style={{ marginTop: "20px", padding: "10px", cursor: "pointer", background: "lightgray", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Close />
        </div>
      </div>
      <h2>Edit Attendance</h2>
      <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
        <p>Punch In Time</p>
        <TextField
          required          
          type="time"
          size="small"
          onTimeUpdate={(e) => { console.log(e) }}
          id="punchInTime"          
          name="punchInTime"
          onChange={handleChangeForTime}
          value={timeSlot.punchInTime}          
        />
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
        <p>Punch Out Time</p>
        <TextField
          required          
          size="small"
          type="time"
          onTimeUpdate={(e) => { console.log(e) }}
          id="punchOutTime"          
          name="punchOutTime"
          onChange={handleChangeForTime}
          value={timeSlot.punchOutTime}
        />
      </FormControl>
      <FormControl sx={{ m: 1,minWidth:150, maxWidth: 150 }} size="small">
        <p>Delay Time</p>
        <TextField
          required          
          type="number"
          size="small"
          onTimeUpdate={(e) => { console.log(e) }}
          id="delayTime"          
          name="delayTime"
          onChange={handleChange}
          value={formData.delayTime}
        />
      </FormControl>
      <FormControl sx={{ m: 1, maxWidth: 150 }} size="small">
        <p>Over Time</p>
        <TextField
          required          
          type="number"
          size="small"
          onTimeUpdate={(e) => { console.log(e) }}
          id="overTime"          
          name="overTime"
          onChange={handleChange}
          value={formData.overTime}
        />
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
        <p>Weekend</p>
        <Select
          value={formData.isWeekend ? "1":"0"}
          onChange={(e: any) => { handleChange(e) }}
          name="isWeekend"
        >
          <MenuItem value={"1"}>Yes</MenuItem>
          <MenuItem value={"0"}>No</MenuItem>
        </Select>
      </FormControl>
      
      <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
        <p>Holiday</p>
        <Select
          value={formData.isHoliday ? "1":"0"}
          onChange={(e: any) => { handleChange(e) }}
          name="isHoliday"
        >
          <MenuItem value={"1"}>Yes</MenuItem>
          <MenuItem value={"0"}>No</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
        <p>Requested For Leave</p>
        <Select
          value={formData.isRequestForLeave ? "1":"0"}
          onChange={(e: any) => { handleChange(e) }}
          name="isRequestForLeave"
        >
          <MenuItem value={"1"}>Yes</MenuItem>
          <MenuItem value={"0"}>No</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
        <p>Denied For Leave</p>
        <Select
          value={formData.isLeaveDenied ? "1":"0"}
          onChange={(e: any) => { handleChange(e) }}
          name="isLeaveDenied"
        >
          <MenuItem value={"1"}>Yes</MenuItem>
          <MenuItem value={"0"}>No</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
        <p>Leave Accepted</p>
        <Select
          value={formData.isLeaveApproved ? "1":"0"}
          onChange={(e: any) => { handleChange(e) }}
          name="isLeaveApproved"
        >
          <MenuItem value={"1"}>Yes</MenuItem>
          <MenuItem value={"0"}>No</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
        <p>On Leave</p>
        <Select
          value={formData.isOnLeaves ? "1":"0"}
          onChange={(e: any) => { handleChange(e) }}
          name="isOnLeaves"
        >
          <MenuItem value={"1"}>Yes</MenuItem>
          <MenuItem value={"0"}>No</MenuItem>
        </Select>
      </FormControl>
      <div style={{margin:"30px",display:"flex",alignItems:"center",justifyContent:"center"}}>
      <Button
                sx={{
                  height: "40px",
                  textDecoration: "none!important",
                  textTransform:"none",
                  fontSize: "16px",
                  color: "white",
                  width:"200px",
                  marginLeft:"10px",
                  background:"green",                                    
                }}
                variant="contained"
                // disabled
                onClick={handleSubmit}
              >
                Update Attendacne
              </Button>
              </div>
    </Container>
  );
}
