import React, { Component } from "react";
import style from "../Meet_History/meet_history.module.css";
import StickyHeadTable from "./StickyHeadTable";
import Pagination_Mui from "../../Small-Components/pagination_mui/Pagination_Mui";
import Pagination_Dropdown from "../../Small-Components/pagination_dropdown/Pagination_Dropdown";
import { connect } from "react-redux";
import { Button, Skeleton } from "@mui/material";
import ApiClient from "../../Api/apiClient";
import TextField from "@mui/material/TextField";
import ButtonWithBackground from "../../Neet-Components/Buttons/ButtonWithBackground";
import EditAttandance from "./UpdateAttendance";
import CsvDownload from "react-csv-downloader";
import moment from "moment";
import ButtonWithIcon from "../../Neet-Components/Buttons/ButtonWithIcon";
interface Meet_HistoryState {
  isShowReceipt: boolean;
  receipt_details: {};
  meeting_history: any;
  pageNo: number;
  pageSize: number;
  loading: string;
  historyDetails: any;
  endDate: any,
  startDate: any,
  seleteAttendaceToEdit: any,
  isShowEditScreen: boolean,
}
interface Meet_HistoryProps {
  empId: string;
  name: string;
}
export class OneEmpAttendance extends Component<
  Meet_HistoryProps,
  Meet_HistoryState
> {
  cardDetails?: any;
  constructor(props: Meet_HistoryProps) {
    super(props);
    this.state = {
      isShowReceipt: false,
      receipt_details: {},
      meeting_history: "",
      pageNo: 0,
      pageSize: 10,
      loading: "pageLoading",
      historyDetails: "",
      endDate: "H",
      startDate: "H",
      seleteAttendaceToEdit: {},
      isShowEditScreen: false,
    };
  }
  componentDidMount(): void {
    this.getHistoryDetailsOfUser(0, 10);
  }
  getHistoryDetailsOfUser = async (pageNo: number, pageSize: number) => {
    this.setState({ loading: "pageLoading" });
    const res = await ApiClient.getInstance().get(`/api/attandance/getAllById?id=${this.props.empId}`);
    console.log(res);
    if (res.isSuccess) {
      this.setState({ loading: "" });
      this.setState({ meeting_history: res.data });
    } else {
      this.setState({ loading: "" });
    }
  };
  onShowReceiptButtonClicked = (receipt_details: any) => {
    this.setState({
      isShowReceipt: !this.state.isShowReceipt,
      receipt_details: receipt_details,
    });
  };
  onCloseReceiptButtonClicked = () => {
    this.setState({
      isShowReceipt: !this.state.isShowReceipt,
      receipt_details: {},
    });
  };
  onNextOrPrevPageButtonClicked = (pageNo: number) => {
    this.setState({ pageNo: pageNo });
    // this.getHistoryDetailsOfUser(pageNo, this.state.pageSize);
  };
  onDropDownButtonClicked = (pageSize: number) => {
    this.setState({ pageSize: pageSize, pageNo: 0 });
    // this.getHistoryDetailsOfUser(0, pageSize);
  };
  handleMonthChange = (event: any) => {
    console.log(event.target.value);
    this.setState({ startDate: event.target.value })
  }
  handleYearChange = (event: any) => {
    console.log(event.target.value);
    this.setState({ endDate: event.target.value })
  }
  onSearchButtonClicked = async () => {
    if (this.state.startDate != "H" && this.state.endDate != "H") {
      this.setState({ loading: "pageLoading" });
      const res = await ApiClient.getInstance().get(`/api/attandance/getAllById?id=${this.props.empId}&startDate=${this.state.startDate}&endDate=${this.state.endDate}`);
      console.log(res);
      if (res.isSuccess) {
        this.setState({ loading: "" });
        this.setState({ meeting_history: res.data });
      } else {
        this.setState({ loading: "" });
        alert("Invalid request with invalid date")
      }
    }
    else {
      alert("Please Select Both Dates")
    }
  }
  onDeleteButtonPressed = async (id: string) => {
    const res = await ApiClient.getInstance().delete(`/api/attandance/delete?id=${id}`);
    this.setState({ loading: "deleteLoading" });
    if (res.isSuccess) {
      this.setState({ loading: "" });
      this.getHistoryDetailsOfUser(0, 10);
      alert("Deleted successfully")
    } else {
      this.setState({ loading: "" });
      alert("Something went wrong")
    }
  }
   timestampToTime = (timestamp:any) => {
    const time = new Date(parseInt(timestamp));
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    const formattedHours = hours % 12 || 12;

    const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;

    console.log(timestamp);
    return formattedTime;
}
  onEditCloseButtonPressed = (isRefresh: boolean) => {
    if (isRefresh) {
      this.getHistoryDetailsOfUser(0, 10);
    }
    this.setState({ isShowEditScreen: false });
  }
  onEditButtonPressed = (seleteAttendaceToEdit: any) => {
    this.setState({ seleteAttendaceToEdit: seleteAttendaceToEdit, isShowEditScreen: true });
  }
  onDownload = async () => {
    try{
    if (this.state.startDate != "H") {
      console.log(this.state.startDate);
      const date: any = this.state.startDate.split("-")
      const data_to_download: any = [];
      const res = await ApiClient.getInstance().get(`/api/employee/genrateSalarySlipByMonths?id=${this.props.empId}&month=${date[1]}&year=${date[0]}`);
      console.log(res);
      if (res.isSuccess && res.data && res.data.dayWiseReport.length > 0) {
        const csvData = res.data;
        console.log("csvData", csvData.monthWiseReport);
        csvData.dayWiseReport.forEach((data: any) => {
          data_to_download.push({
            date: data.date.slice(0, 10),
            name: res.data.employee.firstName + " " + res.data.employee.lastName,
            id: res.data.employee.employeeId,
            // workingHours: res.data.employee.workingHours,
            initalWorkingHours: data.initalWorkingHours,
            workedHours: data.workedHours,
            isHoliday: data.isHoliday ? "Yes" : "No",
            selfLeave: data.selfLeave ? "Yes" : "No",
            missPunchOut: data.missPunchOut ? "Yes" : "No",
            onLeave: data.onLeave ? "Yes" : "No",
            punchInTime: data.punchInTime ? this.timestampToTime(data.punchInTime) : "--",
            punchOutTime: data.punchOutTime ?this.timestampToTime(data.punchOutTime) : "--",
          });
        });
        data_to_download.push({
          date: "",
          name: "",
          id: "",
          // workingHours: "",
          initalWorkingHours: "",
          workedHours: "",
          isHoliday: "",
          selfLeave: "",
          missPunchOut: "",
          onLeave: "",
          punchInTime: "",
          punchOutTime: "",
        });
        data_to_download.push({
          date: "",
          name: "",
          id: "",
          // workingHours: "",
          initalWorkingHours: "",
          workedHours: "",
          isHoliday: "",
          selfLeave: "",
          missPunchOut: "",
          onLeave: "",
          punchInTime: "",
          punchOutTime: "",
        });
        data_to_download.push({
          date: `Monthly Working Hours ${csvData.monthWiseReport.initalWorkingHours}`,
          name: `Monthly Worked Hours ${csvData.monthWiseReport.workedHours}`,
          id: `Monthly Over Time ${csvData.monthWiseReport.overTime}`,
          initalWorkingHours: `Given Leaves For Month ${csvData.monthWiseReport.givenLeavesPerMonth}`,
          workedHours: `Approved Leaves ${csvData.monthWiseReport.approvedLeaves}`,
          isHoliday: `Self Leavevs ${csvData.monthWiseReport.selfLeaves}`,
          selfLeave: `Miss Puch-out Leaves ${csvData.monthWiseReport.missPunchOutLeaves}`,
          missPunchOut: `Other Leaves ${csvData.monthWiseReport.otherLeaves}`,
          onLeave: `Extra Leaves ${csvData.monthWiseReport.extraLeave}`,
          punchInTime: `Salary Per Hours ${csvData.monthWiseReport.salaryPerHours}`,
          punchOutTime: `Obtained Salary For Month ${csvData.monthWiseReport.obtainedSalary}`,
        })
      }
      console.log(`Obtained Salary For Month ${res.data.monthWiseReport.workedHours}`)
      console.log("data_to_download", data_to_download);
      // return;
      return Promise.resolve(data_to_download);
    }
    else {
      alert("Please Select Month And Year")
    }
  }
  catch (error) {
    console.error("Error in onDownload:", error);
    alert("Something went wrong please try again later");
    return Promise.reject(error);
  }

  };
  render() {
    const { loading, meeting_history } =
      this.state;
    const columns = [
      {
        id: "date",
        displayName: "Date",
      },
      {
        id: "id",
        displayName: "Employee Id",
      },
      {
        id: "name",
        displayName: "Employee Name",
      },
      {
        id: "workedHours",
        displayName: "Worked Hours",
      },
      {
        id: "initalWorkingHours",
        displayName: "Working Hours",
      },
      {
        id: "isHoliday",
        displayName: "Holiday",
      },
      {
        id: "selfLeave",
        displayName: "Self Leave",
      },
      {
        id: "missPunchOut",
        displayName: "Miss Punch Out",
      },
      {
        id: "onLeave",
        displayName: "On Leave",
      },
      {
        id: "punchInTime",
        displayName: "Punch In Time",
      },
      // {
      //   id: "punchInLocation",
      //   displayName: "Punch In Location",
      // },
      {
        id: "punchOutTime",
        displayName: "Punch Out Time",
      },
      // {
      //   id: "punchOutLocation",
      //   displayName: "Punch Out Location",
      // },
      // { id: "monthWorkedHours", displayName: "Month Worked Hours" },
      // { id: "monthOverTime", displayName: "Month Overtime" },
      // { id: "monthDelayTime", displayName: "Month Delay Time" },
      // { id: "givenLeavesForMonth", displayName: "Given Leaves for Month" },
      // { id: "approvedLeaves", displayName: "Approved Leaves" },
      // { id: "selfLeavevs", displayName: "Self Leaves" },
      // { id: "missPuchOutLeaves", displayName: "Missed Punch Out Leaves" },
      // { id: "otherLeaves", displayName: "Other Leaves" },
      // { id: "extraLeaves", displayName: "Extra Leaves" },
      // { id: "salaryPerHours", displayName: "Salary Per Hours" },
      // { id: "obtainedSalary", displayName: "Obtained Salary" },
    ];
    if (this.state.loading == "pageLoading") {
      return <>
        <Skeleton height={"70px"} />
        <Skeleton height={"70px"} />
        <Skeleton height={"70px"} />
        <Skeleton height={"70px"} />
        <Skeleton height={"70px"} />
      </>
    }
    else
      return (
        <div className={style.meet_history}>
          {this.state.isShowEditScreen && <div style={{ position: "absolute", top: '0px', left: "0", height: "100vh", width: "100vw", background: "rgb(105 105 105 / 51%)", display: "flex", alignItems: "center", justifyContent: "center", zIndex: "100" }}> <EditAttandance attandanceToEdit={this.state.seleteAttendaceToEdit} onCloseEditModelClicked={this.onEditCloseButtonPressed} /></div>}
          <div className={style.meet_history_heading} style={{margin:"20px"}}>Attendance History Of {this.props?.name}</div>
          <div className={style.meet_history_heading} style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: '20px' }}><TextField
            required
            fullWidth
            label="Select Start Date"
            name="employeeId"
            onChange={this.handleMonthChange}
            type="date"
            value={this.state.startDate}
          />
            <TextField
              required
              fullWidth
              label="Select End Date"
              name="employeeId"
              onChange={this.handleYearChange}
              type="date"
              value={this.state.endDate}
            />
            <ButtonWithBackground buttonName={'Search'} onClick={() => { this.onSearchButtonClicked() }} buttonLoading={false} />
            <CsvDownload
              columns={columns}
              datas={this.onDownload}
              filename={`${this.props.name} Monthly Report`}
              extension=".csv"
              disabled={this.state.startDate == "H"}
              // separator=";"
              style={{ width: "100%" }}
            >
              <ButtonWithIcon background={'green'} buttonName={'Download Employee Report'} buttonLoading={false} />
            </CsvDownload>
          </div>

          <div className={style.meet_history_table}>
            {loading === "pageLoading" ? (
              <>
                <Skeleton height={"70px"} />
                <Skeleton height={"70px"} />
                <Skeleton height={"70px"} />
                <Skeleton height={"70px"} />
                <Skeleton height={"70px"} />
              </>
            ) : (
              <StickyHeadTable
                // onShowReceiptButtonClicked={this.onShowReceiptButtonClicked}
                meeting_history={meeting_history.data}
                onDeleteButtonPressed={this.onDeleteButtonPressed}
                onEditButtonPressed={this.onEditButtonPressed}
              />
            )}
          </div>
          <div className={style.meet_history_pagination}>
            <Pagination_Dropdown
              onDropDownButtonClicked={this.onDropDownButtonClicked}
              pageSize={this.state.pageSize}
            />
            <Pagination_Mui
              onNextOrPrevPageButtonClicked={this.onNextOrPrevPageButtonClicked}
              recordCount={meeting_history.totalCount}
              pageSize={this.state.pageSize}
              pageNo={this.state.pageNo}
            />
          </div>
        </div>
      );
  }
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OneEmpAttendance);
