import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ButtonWithIcon from "../../Neet-Components/Buttons/ButtonWithIcon";
import moment from "moment";

interface Column {
  id: "punchInTime" |"punchInLocation"|"punchOutLocation" |"punchOutTime" | "isOnLeaves" | "leaveResion" | "createdAt" | 'edit' | "status";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

interface StickyHeadTableProps {
  meeting_history: any[]; onDeleteButtonPressed: Function;
  onEditButtonPressed: Function;
}

export default function StickyHeadTable({
  meeting_history, onDeleteButtonPressed, onEditButtonPressed
}: StickyHeadTableProps) {
  const columns: readonly Column[] = [
    { id: "createdAt", label: "Date", minWidth: 140 },
    { id: "punchInTime", label: "Punch In Time", minWidth: 140 },
    
    {
      id: "punchInLocation",
      label: "Punch In Location", minWidth: 140 
    },
    { id: "punchInTime", label: "Punch In Time", minWidth: 140 },
    { id: "punchOutLocation", label: "Punch Out Location", minWidth: 140 },
    // { id: "isOnLeaves", label: "On Leaves", minWidth: 140 },
    { id: "leaveResion", label: "Leave Reason", minWidth: 140 },
    // { id: "isHoliday", label: "Is Holiday", minWidth: 140 },
    // { id: "isHoliday", label: "Actions", minWidth: 140 },
    { id: "status", label: "Status", minWidth: 140 },
    { id: "edit", label: "Actions", minWidth: 140 },    
    
  ];
  const calculateStatus=(values:any)=>{
    if(values.punchInTime && values.punchOutTime){
      return "Present"
    }
    else if(values.punchInTime || values.punchOutTime){
      return "Miss Punching"
    }
    else if(values.isOnLeaves){
      return "On Leave"
    }
    else if(values.onHoliday){
      return "On Holiday"
    }
    else if(!values.punchInTime || !values.punchOutTime){
      return "Leave By Own"
    }
    else{
      return "not detected"

    }
  }
  const timestampToTime = (timestamp:any) => {
    const time = new Date(parseInt(timestamp));
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    const formattedHours = hours % 12 || 12;

    const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;

    console.log(timestamp);
    return formattedTime;
}
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align || "left"}
                  style={{
                    minWidth: column.minWidth,
                    fontWeight: "700",
                    color: "#4F4D55",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {meeting_history.map((row: any) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row._id}
                style={{ cursor: "pointer" }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align || "left"}
                    style={{ color: column.id === "isOnLeaves" ? row.isOnLeaves ? 'red' : "green" : "" }}
                  >
                    {column.id === "createdAt" && (row.createdAt ? row.createdAt.slice(0, 10) : "--")}
                    {column.id === "punchInTime" && (row.punchInTime ? timestampToTime(row.punchInTime) : "--")}
                    {column.id === "punchInLocation" && (row.punchInLocation ? row.punchInLocation : "No Location")}
                    {column.id === "punchOutTime" && (row.punchOutTime ? timestampToTime(row.punchOutTime) : '--')}
                    {column.id === "punchOutLocation" && (row.punchOutLocation ? row.punchOutLocation : "No Location")}
                    {/* {column.id === "isOnLeaves" && (row.isOnLeaves ? "Yes" : "No")} */}
                    {column.id === "leaveResion" && (row.leaveResion ? row.leaveResion : "No Resion")}
                    {/* {column.id === "isHoliday" && (row.isHoliday ? "Yes" : "No")} */}
                    {column.id === "status" && <p style={{fontWeight:"600",fontSize:"18px"}}>{calculateStatus(row)}</p>}
                    {column.id === "edit" && (<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "20px" }}>
                      {/* <CsvDownload
              columns={columns}
              datas={this.onDownload}
              filename="userdata"
              extension=".csv"
              // separator=";"
            ><ButtonWithIcon background={'red'} buttonName={"Delete"} onClick={() => { onDeleteButtonPressed(row._id) }} /></CsvDownload> */}
                      <ButtonWithIcon background={'green'} buttonName={"Edit"} onClick={() => { onEditButtonPressed(row) }} /></div>)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {meeting_history.length <= 0 && <h2 style={{ width: "100%", textAlign: "center" }}>No Data Found</h2>}
    </Paper>
  );
}