import { Close } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';
import React, { Component } from 'react'

interface Props{
    onHolidayCloseButtonPressed:any;
    onUpdate:any;
    makeHolidayForAll:any;
    loading:string;
}
export default class HolidayPopup extends Component <Props>{
  render() {
    return (
      <div style={{padding:"20px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",background:"#fff",borderRadius:"20px",gap:"20px"}}>
        <div style={{width:"100%",display:"flex",alignItems:"flex-end",justifyContent:"flex-end"}}>
        <div onClick={this.props.onHolidayCloseButtonPressed} style={{marginTop:"20px",padding:"10px",cursor:"pointer",background:"lightgray",borderRadius:"50%",display:"flex",alignItems:"center",justifyContent:"center"}}>
        <Close />
        </div>
        </div>
        <h2>Holiday Resion</h2>
        <TextField
            required
            fullWidth
            id="role"            
            name="role"
            onChange={(e)=>{this.props.onUpdate(e.target.value)}}            
          />
          <Button
                sx={{
                  height: "40px",
                  textDecoration: "none!important",
                  textTransform:"none",
                  fontSize: "16px",
                  color: "white",
                  width:"200px",
                }}
                variant="contained"
                onClick={this.props.makeHolidayForAll}>
              {this.props.loading=="holiday"?"Loading...":"Make A Holiday"}
          </Button>
      </div>
    )
  }
}
