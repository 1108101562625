import React, { Component } from "react";
import style from "./meet_history.module.css";
import StickyHeadTable from "../Table_mui/StickyHeadTable";
import Pagination_Mui from "../../Small-Components/pagination_mui/Pagination_Mui";
import Pagination_Dropdown from "../../Small-Components/pagination_dropdown/Pagination_Dropdown";
import { connect } from "react-redux";
import { Skeleton, TextField } from "@mui/material";
import ApiClient from "../../Api/apiClient";
import EditAgent from "../Add_Agents/Edit_Agent";
import ButtonWithIcon from "../../Neet-Components/Buttons/ButtonWithIcon";
import ButtonWithBackground from "../../Neet-Components/Buttons/ButtonWithBackground";
import moment from "moment";
import CsvDownload from "react-csv-downloader";

interface Meet_HistoryState {
  isShowReceipt: boolean;
  receipt_details: {};
  meeting_history: any;
  pageNo: number;
  pageSize: number;
  loading: string;
  historyDetails: any;
  showEmpEditModel: boolean,
  empToEdit: any,
  endDate: any,
  startDate: any,
  empToSearch: string,
}
interface Meet_HistoryProps {
  onShowReceiptButtonClicked: Function;
}
export class Meet_History extends Component<
  Meet_HistoryProps,
  Meet_HistoryState
> {
  cardDetails?: any;
  constructor(props: Meet_HistoryProps) {
    super(props);
    this.state = {
      isShowReceipt: false,
      receipt_details: {},
      meeting_history: [],
      pageNo: 1,
      pageSize: 10,
      loading: "pageLoading",
      historyDetails: "",
      showEmpEditModel: false,
      empToEdit: {},
      endDate: "H",
      startDate: "H",
      empToSearch: "",
    };
  }
  componentDidMount(): void {
    this.getHistoryDetailsOfUser(1, 10);
  }
  getHistoryDetailsOfUser = async (pageNo: number, pageSize: number) => {
    this.setState({ loading: "pageLoading" });
    const res = await ApiClient.getInstance().get(`/api/employee/getEmpPage?pageSize=${pageSize}&pageNo=${pageNo}`);
    console.log(res);
    if (res.isSuccess) {
      this.setState({ loading: "" });
      this.setState({ meeting_history: res.data });
    } else {
      this.setState({ loading: "" });
    }
  };
  onShowReceiptButtonClicked = (receipt_details: any) => {
    this.setState({
      isShowReceipt: !this.state.isShowReceipt,
      receipt_details: receipt_details,
    });
  };
  onCloseReceiptButtonClicked = () => {
    this.setState({
      isShowReceipt: !this.state.isShowReceipt,
      receipt_details: {},
    });
  };
  onNextOrPrevPageButtonClicked = (pageNo: number) => {
    this.setState({ pageNo: pageNo });
    this.getHistoryDetailsOfUser(pageNo, this.state.pageSize);
  };
  onDropDownButtonClicked = (pageSize: number) => {
    this.setState({ pageSize: pageSize, pageNo: 1 });
    this.getHistoryDetailsOfUser(1, pageSize);
  };
  onEditButtonPressed = async (empToEdit: any) => {
    this.setState({ empToEdit: empToEdit, showEmpEditModel: true });
  }
  onCloseEditModelClicked = (model?: boolean) => {
    if (true) {
      this.getHistoryDetailsOfUser(1, 10);
    }
    this.setState({ empToEdit: {}, showEmpEditModel: false });
  }
  onDeleteButtonPressed = async (id: string) => {
    const res = await ApiClient.getInstance().delete(`/api/employee/delete?id=${id}`);
    this.setState({ loading: "deleteLoading" });
    if (res.isSuccess) {
      this.setState({ loading: "" });
      this.getHistoryDetailsOfUser(0, 10);
      alert("Deleted successfully")
    } else {
      this.setState({ loading: "" });
      alert("Something went wrong")
    }
  }
  timestampToTime = (timestamp:any) => {
    const time = new Date(parseInt(timestamp));
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    const formattedHours = hours % 12 || 12;

    const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;

    console.log(timestamp);
    return formattedTime;
}
  handleMonthChange = (event: any) => {
    console.log(event.target.value);
    this.setState({ startDate: event.target.value })
  }
  handleYearChange = (event: any) => {
    console.log(event.target.value);
    this.setState({ endDate: event.target.value })
  }
  onDownloadReportButtonClicked = async () => {
    if (this.state.startDate != "H" && this.state.endDate != "H") {
      this.setState({ loading: "pageLoading" });
      const res: any = {};
      console.log(res);
      if (res.isSuccess) {
        this.setState({ loading: "" });
        this.setState({ meeting_history: res.data });
      } else {
        this.setState({ loading: "" });
        alert("Invalid request with invalid date")
      }
    }
    else {
      alert("Please Select Both Dates")
    }
  }
  onSearchButtonClicked = async () => {
    if (this.state.empToSearch) {
      this.setState({ loading: "searchLoading" });
      const res: any = await ApiClient.getInstance().get(`/api/employee/search?employeeId=${this.state.empToSearch}`);
      console.log(res);
      if (res.isSuccess && res.statusCode === 200) {
        if (res.data.length > 0) {
          this.setState({ loading: "" });
          this.setState({ meeting_history: res });
          this.setState({ empToSearch: "" })
        }
        else {
          this.setState({ loading: "" });
          this.setState({ empToSearch: "" })
          alert("No employee found")
        }
      }
      else if (!res.isSuccess && res.statusCode === 400) {
        this.setState({ loading: "" });
        alert("No employee found")
        this.setState({ empToSearch: "" })
      }
      else {
        this.setState({ loading: "" });
        alert("something went wrong")
        this.setState({ empToSearch: "" })
      }
    }
    else {
      alert("Invalid employee id")
    }
  }

  onDownload2 = async () => {
    if (this.state.startDate != "H") {
      console.log(this.state.startDate);
      const date: any = this.state.startDate.split("-")
      const data_to_download: any = [];
      const res = await ApiClient.getInstance().get(`/api/employee/genrateAllEmpMonthlyDetails?month=${date[1]}&year=${date[0]}`);
      console.log(res);
      if (res.data.length > 1 && res.data) {
        res.data.map(({ employee, dayWiseReport, monthWiseReport }: any) => {
          if (res.isSuccess && res.data && res.data.dayWiseReport.length > 0) {
            dayWiseReport.forEach((data: any) => {
              data_to_download.push({
                date: data.date.slice(0, 10),
                name: employee.firstName + " " + employee.lastName,
                id: employee.employeeId,
                // workingHours: res.data.employee.workingHours,
                initalWorkingHours: data.initalWorkingHours,
                workedHours: data.workedHours,
                isHoliday: data.isHoliday ? "Yes" : "No",
                selfLeave: data.selfLeave ? "Yes" : "No",
                missPunchOut: data.missPunchOut ? "Yes" : "No",
                onLeave: data.onLeave ? "Yes" : "No",
                punchInTime: data.punchInTime ? this.timestampToTime(data.punchInTime) : "--",
                punchOutTime: data.punchOutTime ? this.timestampToTime(data.punchOutTime) : "--",
              });
            });
            data_to_download.push({
              date: "",
              name: "",
              id: "",
              // workingHours: "",
              initalWorkingHours: "",
              workedHours: "",
              isHoliday: "",
              selfLeave: "",
              missPunchOut: "",
              onLeave: "",
              punchInTime: "",
              punchOutTime: "",
            });
            data_to_download.push({
              date: "",
              name: "",
              id: "",
              // workingHours: "",
              initalWorkingHours: "",
              workedHours: "",
              isHoliday: "",
              selfLeave: "",
              missPunchOut: "",
              onLeave: "",
              punchInTime: "",
              punchOutTime: "",
            });
            data_to_download.push({
              date: `Monthly Working Hours ${monthWiseReport.initalWorkingHours}`,
              name: `Monthly Worked Hours ${monthWiseReport.workedHours}`,
              id: `Monthly Over Time ${monthWiseReport.overTime}`,
              initalWorkingHours: `Given Leaves For Month ${monthWiseReport.givenLeavesPerMonth}`,
              workedHours: `Approved Leaves ${monthWiseReport.approvedLeaves}`,
              isHoliday: `Self Leavevs ${monthWiseReport.selfLeaves}`,
              selfLeave: `Miss Puch-out Leaves ${monthWiseReport.missPunchOutLeaves}`,
              missPunchOut: `Other Leaves ${monthWiseReport.otherLeaves}`,
              onLeave: `Extra Leaves ${monthWiseReport.extraLeave}`,
              punchInTime: `Salary Per Hours ${monthWiseReport.salaryPerHours}`,
              punchOutTime: `Obtained Salary For Month ${monthWiseReport.obtainedSalary}`,
            })
          }
        })
      }
      return Promise.resolve(data_to_download);
    }
    else {
      alert("Please Select Both Dates")
    }
  };
  onDownload = async () => {
    try {
      if (this.state.startDate !== "H") {
        console.log(this.state.startDate);
        const date = this.state.startDate.split("-");
        const data_to_download: any = [];
        const res = await ApiClient.getInstance().get(`/api/employee/genrateAllEmpMonthlyDetails?month=${date[1]}&year=${date[0]}`);
        console.log(res);
        if (res.data.length > 1 && res.data) {
          res.data.forEach(({ employee, dayWiseReport, monthWiseReport }: any) => {
            if (res.isSuccess && res.data && dayWiseReport.length > 0) {
              dayWiseReport.forEach((data: any) => {
                data_to_download.push({
                  date: data.date.slice(0, 10),
                  name: `${employee.firstName} ${employee.lastName}`,
                  id: employee.employeeId,
                  initalWorkingHours: data.initalWorkingHours,
                  workedHours: data.workedHours,
                  isHoliday: data.isHoliday ? "Yes" : "No",
                  selfLeave: data.selfLeave ? "Yes" : "No",
                  missPunchOut: data.missPunchOut ? "Yes" : "No",
                  onLeave: data.onLeave ? "Yes" : "No",
                  punchInTime: data.punchInTime ? moment.unix(data.punchInTime).format('hh:mm A') : "--",
                  punchOutTime: data.punchOutTime ? moment.unix(data.punchOutTime).format('hh:mm A') : "--",
                });
              });

              // Add empty rows
              for (let i = 0; i < 2; i++) {
                data_to_download.push({
                  date: "",
                  name: "",
                  id: "",
                  initalWorkingHours: "",
                  workedHours: "",
                  isHoliday: "",
                  selfLeave: "",
                  missPunchOut: "",
                  onLeave: "",
                  punchInTime: "",
                  punchOutTime: "",
                });
              }

              data_to_download.push({
                date: `Monthly Working Hours ${monthWiseReport.initalWorkingHours}`,
                name: `Monthly Worked Hours ${monthWiseReport.workedHours}`,
                id: `Monthly Over Time ${monthWiseReport.overTime}`,
                initalWorkingHours: `Given Leaves For Month ${monthWiseReport.givenLeavesPerMonth}`,
                workedHours: `Approved Leaves ${monthWiseReport.approvedLeaves}`,
                isHoliday: `Self Leavevs ${monthWiseReport.selfLeaves}`,
                selfLeave: `Miss Puch-out Leaves ${monthWiseReport.missPunchOutLeaves}`,
                missPunchOut: `Other Leaves ${monthWiseReport.otherLeaves}`,
                onLeave: `Extra Leaves ${monthWiseReport.extraLeave}`,
                punchInTime: `Salary Per Hours ${monthWiseReport.salaryPerHours}`,
                punchOutTime: `Obtained Salary For Month ${monthWiseReport.obtainedSalary}`,
              });

              for (let i = 0; i < 5; i++) {
                data_to_download.push({
                  date: "",
                  name: "",
                  id: "",
                  initalWorkingHours: "",
                  workedHours: "",
                  isHoliday: "",
                  selfLeave: "",
                  missPunchOut: "",
                  onLeave: "",
                  punchInTime: "",
                  punchOutTime: "",
                });
              }
            }
          });
        }

        return Promise.resolve(data_to_download);
      } else {
        alert("Please Start Date");
        return Promise.reject("Dates not selected");
      }
    } catch (error) {
      console.error("Error in onDownload:", error);
      alert("Something went wrong please try again later");
      return Promise.reject(error);
    }
  };

  render() {
    const { loading, meeting_history } =
      this.state;
    const columns = [
      {
        id: "date",
        displayName: "Date",
      },
      {
        id: "id",
        displayName: "Employee Id",
      },
      {
        id: "name",
        displayName: "Employee Name",
      },
      {
        id: "workedHours",
        displayName: "Worked Hours",
      },
      {
        id: "initalWorkingHours",
        displayName: "Working Hours",
      },
      {
        id: "isHoliday",
        displayName: "Holiday",
      },
      {
        id: "selfLeave",
        displayName: "Self Leave",
      },
      {
        id: "missPunchOut",
        displayName: "Miss Punch Out",
      },
      {
        id: "onLeave",
        displayName: "On Leave",
      },
      {
        id: "punchInTime",
        displayName: "Punch In Time",
      },
      {
        id: "punchOutTime",
        displayName: "Punch Out Time",
      },
      // { id: "monthWorkedHours", displayName: "Month Worked Hours" },
      // { id: "monthOverTime", displayName: "Month Overtime" },
      // { id: "monthDelayTime", displayName: "Month Delay Time" },
      // { id: "givenLeavesForMonth", displayName: "Given Leaves for Month" },
      // { id: "approvedLeaves", displayName: "Approved Leaves" },
      // { id: "selfLeavevs", displayName: "Self Leaves" },
      // { id: "missPuchOutLeaves", displayName: "Missed Punch Out Leaves" },
      // { id: "otherLeaves", displayName: "Other Leaves" },
      // { id: "extraLeaves", displayName: "Extra Leaves" },
      // { id: "salaryPerHours", displayName: "Salary Per Hours" },
      // { id: "obtainedSalary", displayName: "Obtained Salary" },
    ];
    if (this.state.loading == "pageLoading") {
      return <>
        <Skeleton height={"70px"} />
        <Skeleton height={"70px"} />
        <Skeleton height={"70px"} />
        <Skeleton height={"70px"} />
        <Skeleton height={"70px"} />
      </>
    }
    else
      return (
        <div className={style.meet_history}>
          {this.state.showEmpEditModel && <div style={{ position: "absolute", top: '0px', left: "0", height: "100vh", width: "100vw", background: "rgb(105 105 105 / 51%)", display: "flex", alignItems: "center", justifyContent: "center", zIndex: "100" }}>
            <EditAgent empToEdit={this.state.empToEdit} onCloseEditModelClicked={this.onCloseEditModelClicked} />
          </div>
          }
          <div className={style.meet_history_heading}>All Employees</div>
          <div className={style.meet_history_heading} style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: '20px' }}><TextField
            required
            fullWidth
            label="Select A Date"
            name="employeeId"
            onChange={this.handleMonthChange}
            type="date"
            value={this.state.startDate}
          />
            {/* <TextField
              required
              fullWidth
              label="Select End Date"
              name="employeeId"
              onChange={this.handleYearChange}
              type="date"
              value={this.state.endDate}
            /> */}
            <CsvDownload
              columns={columns}
              datas={this.onDownload}
              filename="EmployeesMontlyReports"
              extension=".csv"
              style={{ width: "800px" }}
              disabled={this.state.startDate == "H"}
            // separator=";"
            >
              <ButtonWithIcon background={'green'} buttonName={'Download Employee Report'} buttonLoading={false} />
            </CsvDownload>
            {/* <ButtonWithBackground loading={this.state.loading === "searchLoading"} buttonName={'Download All Emp Report'} onClick={() => { this.onDownload() }} buttonLoading={false} /> */}
          </div>
          <div className={style.meet_history_heading} style={{ width: "500px", display: "flex", alignItems: "center", justifyContent: "center", gap: '20px' }}>
            <TextField
              required
              fullWidth
              label="Employee Id"
              name="employeeId"
              onChange={(e) => { this.setState({ empToSearch: e.target.value }) }}
              type="text"
              value={this.state.empToSearch}
            />
            <ButtonWithBackground loading={this.state.loading === "searchLoading"} buttonName={'Search'} onClick={() => { this.onSearchButtonClicked() }} buttonLoading={false} />
          </div>
          <div className={style.meet_history_table}>
            {loading === "pageLoading" ? (
              <>
                <Skeleton height={"70px"} />
                <Skeleton height={"70px"} />
                <Skeleton height={"70px"} />
                <Skeleton height={"70px"} />
                <Skeleton height={"70px"} />
              </>
            ) : (
              <StickyHeadTable
                meeting_history={meeting_history.data}
                onShowReceiptButtonClicked={this.props.onShowReceiptButtonClicked}
                onDeleteButtonPressed={this.onDeleteButtonPressed}
                onEditButtonPressed={this.onEditButtonPressed}
                loading={this.state.loading}
              />
            )}
          </div>
          <div className={style.meet_history_pagination}>
            <Pagination_Dropdown
              onDropDownButtonClicked={this.onDropDownButtonClicked}
              pageSize={this.state.pageSize}
            />
            <Pagination_Mui
              onNextOrPrevPageButtonClicked={this.onNextOrPrevPageButtonClicked}
              recordCount={meeting_history.totalCount}
              pageSize={this.state.pageSize}
              pageNo={this.state.pageNo}
            />
          </div>
        </div>
      );
  }
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Meet_History);
