import { MenuItem, Select } from '@mui/material'
import React, { Component } from 'react'
import style from "./pagination_dropdown.module.css"

interface PaginationDropdownProps{
  onDropDownButtonClicked:Function;
  pageSize:number;
}
interface PaginationDropdownState{
 
}
export default class Pagination_Dropdown extends Component <PaginationDropdownProps,PaginationDropdownState>{
  onPageSizeChange=(e:any)=>{
  this.props.onDropDownButtonClicked(e.target.value);      
  }
  render() { 
    return (
      <div className={style.dropdown}>
        Rows Per Page
        <Select
        id="demo-select-small"
        value={this.props.pageSize}
        sx={{height:"30px"}}
        onChange={this.onPageSizeChange}
        className={style.dropdown_select}
      >
        <MenuItem value={10} sx={{fontStyle:"Inter"}}>
          10
        </MenuItem>
        {/* <MenuItem value={1}>Ten</MenuItem> */}
        <MenuItem value={20} sx={{fontStyle:"Inter"}}>20</MenuItem>
        <MenuItem value={30} sx={{fontStyle:"Inter"}}>30</MenuItem>
      </Select>
      </div>
    )
  }
}
