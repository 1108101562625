import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ButtonWithIcon from "../../Neet-Components/Buttons/ButtonWithIcon";

interface Column {
  id: "firstName"  | "salary" | "employeeId" | "shiftStartTime" | "shiftEndTime" | "leavePerMonth"|'edit';
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

interface StickyHeadTableProps {
  meeting_history: any;
  onShowReceiptButtonClicked:Function;
  onDeleteButtonPressed:Function;
  loading:string;onEditButtonPressed:Function;
}
export default function StickyHeadTable({
  meeting_history,
  onShowReceiptButtonClicked,
  onDeleteButtonPressed,
  loading,onEditButtonPressed
}: StickyHeadTableProps) {
  const columns: readonly Column[] = [
    { id: "employeeId", label: "Id", minWidth: 140 },    
    { id: "firstName", label: "Name", minWidth: 140 },   
    { id: "salary", label: "Salary", minWidth: 140 },
    { id: "shiftStartTime", label: "shiftStartTime", minWidth: 140 },
    { id: "shiftEndTime", label: "shiftEndTime", minWidth: 140 },
    { id: "leavePerMonth", label: "leavePerMonth", minWidth: 140 },
    { id: "edit", label: "Actions", minWidth: 140 },   
  ];
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    fontWeight: "700",
                    color: "#4F4D55",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {meeting_history.map((row: any) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row}
                  style={{ cursor: "pointer" }}
                >
                  {columns.map((column) => {
                    const value = row;
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}                        
                      >{column.id === "employeeId" &&
                      value.employeeId}             
                        {column.id === "firstName" &&
                          value.firstName+' '+value.lastName}                                     
                          {column.id === "salary" &&
                          value.salary}      
                          {column.id === "shiftStartTime" && value.shiftStartTime}                  
                          {column.id === "shiftEndTime" && value.shiftEndTime}                  
                          {column.id === "leavePerMonth" && value.leavePerMonth}                  
                          {column.id === "edit" && (<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "20px" }}><ButtonWithIcon background={'red'} buttonName={"Delete"} onClick={() => { onDeleteButtonPressed(row._id) }} buttonLoading={loading==="deleteLoading"}/><ButtonWithIcon background={'green'} buttonName={"Edit"} onClick={()=>{onEditButtonPressed(value)}}/><ButtonWithIcon background={'blue'} buttonName={"Attandance"} onClick={() => {
                          onShowReceiptButtonClicked(row._id,row.firstName+" "+row.lastName);
                        }}/></div>)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
