import axios from 'axios';
import BaseUrl from './BaaseUrl';
export default class ApiClientAxios {
    baseUrl: string;
    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }
    static apiClientAxios=new ApiClientAxios(process.env.REACT_APP_API_BASE_URL_LOCAL as string);
   static getInstance=()=>{
        if(!ApiClientAxios.apiClientAxios){
            ApiClientAxios.apiClientAxios=new ApiClientAxios(process.env.REACT_APP_API_BASE_URL_LOCAL as string);
        }
        return ApiClientAxios.apiClientAxios;
    }
    async get(url: string) {
        try {
            const response = await BaseUrl.get(url);
            return response.data;
        } catch (error: any) {
            console.log(error);
            return error.response;
            throw new Error(`GET request to ${url} failed: ${error.message}`);
        }
    }

    async post(url: string, data: Object) {
        try {
            const response = await BaseUrl.post(url, data);
            return response;
        } catch (error: any) {
            console.log(error);
            return error.response;            
            throw new Error(`POST request to ${url} failed: ${error.message}`);
        }
    }

    async put(url: string, data: object) {
        try {
            const response = await BaseUrl.put(url, data);
            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
            throw new Error(`PUT request to ${url} failed: ${error.message}`);
        }
    }

    async delete(url: string) {
        try {
            const response = await BaseUrl.delete(url);
            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
            throw new Error(`DELETE request to ${url} failed: ${error.message}`);
        }
    }
}

