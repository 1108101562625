import React, { Component, useEffect, useRef, useState } from 'react'
import logo from "../../Assets/logo.jpg";
import jsPDF from 'jspdf';
import "./style.css"
import ApiClient from '../../Api/apiClient';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Loading_Page from '../../Neet-Components/Loading-Page/Loading_Page';
import ButtonWithBackground from '../../Neet-Components/Buttons/ButtonWithBackground';
import ButtonWithIcon from '../../Neet-Components/Buttons/ButtonWithIcon';


export default function Salary_Slip_Genrator() {
    const reportTemplateRef = useRef(null);
     const [employees, setemployees] = useState([])
     const [loading, setloading] = useState(false)
     const [selectedEmp, setselectedEmp]:any = React.useState({});
    const handleGeneratePdf = async () => {
        const doc = new jsPDF({
            format: 'a4',
            unit: 'px',
        });

        console.log('pdf called');

        const template: any = reportTemplateRef?.current;

        if (template) {
            // Adding the fonts.
            doc.setFont('Inter-Regular', 'normal');

            // Measure the height of the HTML content
            const { height } = template?.getBoundingClientRect();

            // Set the height of the PDF canvas to accommodate the entire content
            doc.html(template, {
                html2canvas: { scale: 0.27 },
                margin: 10,
                callback: async (doc: any) => {
                    await doc.save(`${selectedEmp.firstName} ${selectedEmp.lastName} Salary Slip`);
                },
                x: 10,
                y: 10,
                width: doc.internal.pageSize.getWidth() - 20,
                // height: height + 20, // Adjust as needed
            });
        } else {
            // Handle the case when reportTemplateRef?.current is null.
            console.error('Template is null. Unable to generate PDF.');
        }
    };

const  getHistoryDetailsOfUser = async () => {
        setloading(true)
        const res = await ApiClient.getInstance().get(`/api/employee/get`);
        console.log(res);
        if (res.isSuccess) {
            setloading(false)
            setemployees(res.data)          
            setselectedEmp(res.data[0])
            console.log(res.data[0])
        } else {
            setloading(false)
            alert("Something went wrong please try again later")
        }
      };
      const handleChange = (event: SelectChangeEvent) => {
        setselectedEmp(event.target.value as string);        
        console.log(event.target.value);
      };
    
    useEffect(() => {
        // handleGeneratePdf();
        getHistoryDetailsOfUser();
    }, [])
    if(loading){
        <Loading_Page/>
    }
    return (<div>
         <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select Employee</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedEmp?.employeeId}
          label="Select Employee"
          onChange={handleChange}
          defaultValue={"Default Emp"}
        >
        {employees.map((emp:any)=>{
            return <MenuItem value={emp}>{emp.employeeId} {"-----"}{emp.firstName} {" "}{emp.lastName}</MenuItem>          
        })}          
        </Select>
      </FormControl>
    </Box>
        <div className="salary-slip" ref={reportTemplateRef} style={{border:"1px solid gray"}}>
            <table className="empDetail" >
                <tr style={{ backgroundColor: "lightgray", height: "100px" }}>
                    <td colSpan={4}>
                        <img height="90px" src={logo} /></td>
                    <td style={{textAlign:"center"}} colSpan={4} className="companyName">Shivaay Roadside Assistance India.<br />
                        <p style={{ fontWeight: "400", fontSize: "17px", textAlign: "center" }}>North Delhi, West Delhi, South Delhi & East Delhi</p>
                        <p style={{ fontWeight: "400", fontSize: "17px", textAlign: "center" }}>Pay Slip Of Month</p>
                        <p style={{ fontWeight: "400", fontSize: "17px", textAlign: "center" }}><input defaultValue={"April 2029"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px",textAlign:"center"}}/></p>
                    </td>
                    {/* <td colSpan={4} className="companyName" style={{fontWeight:'400',fontSize:"16px"}}></td> */}
                </tr>
                <tr>
                    <th>
                        Name
                    </th>
                    <td>
                        <input value={selectedEmp.firstName+" "+selectedEmp.lastName} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                    <td></td>
                    <th>
                        Bank Code
                    </th>
                    <td>
                    <input defaultValue={"--"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px",}}/>
                    </td>
                    <td></td>
                    <th>
                        Branch Name
                    </th>
                    <td>
                    <input defaultValue={"--"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                </tr>
                <tr>
                    <th>
                        Employee Code
                    </th>
                    <td>
                    <input defaultValue={selectedEmp.employeeId} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                    <td></td>
                    <th>
                        Bank Name
                    </th>
                    <td>
                    <input defaultValue={"--"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                    <td></td>
                    <th>
                        Payslip no.
                    </th>
                    <td>
                    <input defaultValue={"SRAI-01"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                </tr>
                <tr>
                    <th>
                        Cost Centre
                    </th>
                    <td>
                    <input defaultValue={"--"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td><td></td>
                    <th>
                        Bank Branch
                    </th>
                    <td>
                    <input defaultValue={"--"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td><td></td>
                    <th>
                        Pay Period
                    </th>
                    <td>
                    <input defaultValue={"--"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                </tr>
                <tr>
                    <th>
                        CC Description:
                    </th>
                    <td>
                    <input defaultValue={"--"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td><td></td>
                    <th>
                        Bank A/C no.
                    </th>
                    <td>
                    <input defaultValue={selectedEmp.bankAcNumber} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td><td></td>
                    <th>
                        PAN No:
                    </th>
                    <td>
                    <input defaultValue={selectedEmp.panNo} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                </tr>
                <tr>
                    <th>
                        Joining Date
                    </th>
                    <td>
                    <input defaultValue={selectedEmp.joiningDate} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td><td></td>
                    <th>
                        Employee Group
                    </th>
                    <td>
                    <input defaultValue={selectedEmp.role} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td><td></td>
                    <th>
                        Personel Area
                    </th>
                    <td>
                    {/* <textarea  defaultValue={"--"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px",height:"100px"}}/> */}
                    <input defaultValue={selectedEmp.location} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>                    
                </tr>
                <tr className="myBackground">
                    <th colSpan={2}>
                        Payments
                    </th>
                    <th >
                        Particular
                    </th>
                    <th className="table-border-right">
                        Amount (Rs.)
                    </th>
                    <th colSpan={2}>
                        Deductions
                    </th>
                    <th >
                        Particular
                    </th>
                    <th >
                        Amount (Rs.)
                    </th>
                </tr>
                <tr>
                    <th colSpan={2}>
                        Basic Salary
                    </th>
                    <td></td>
                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                    <th colSpan={2} >
                        Provident Fund
                    </th >
                    <td></td>

                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                </tr >
                <tr>
                    <th colSpan={2}>
                        Fixed Dearness Allowance
                    </th>
                    <td></td>

                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                    <th colSpan={2} >
                        LIC
                    </th >
                    <td></td>

                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                </tr >
                <tr>
                    <th colSpan={2}>
                        Variable Dearness Allowance
                    </th>
                    <td></td>

                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                    <th colSpan={2} >
                        Loan
                    </th >
                    <td></td>

                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                </tr >
                <tr>
                    <th colSpan={2}>
                        House Rent Allowance
                    </th>
                    <td></td>
                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                    <th colSpan={2} >
                        Professional Tax
                    </th >
                    <td></td>
                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                </tr >
                <tr>
                    <th colSpan={2}>
                        Graduation Allowance
                    </th>
                    <td></td>

                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                    <th colSpan={2} >
                        Security Deposite(SD)
                    </th >
                    <td></td>

                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                </tr >
                <tr>
                    <th colSpan={2}>
                        Conveyance Allowance
                    </th> <td></td>
                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                    <th colSpan={2} >
                        Staff Benefit(SB)
                    </th >
                    <td></td>
                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                </tr >
                <tr>
                    <th colSpan={2}>
                        Post Allowance
                    </th>
                    <td></td>
                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                    <th colSpan={2} >
                        Labour Welfare Fund
                    </th >
                    <td></td>
                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                </tr >
                <tr>
                    <th colSpan={2}>
                        Special Allowance
                    </th>
                    <td></td>
                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                    <th colSpan={2} >
                        NSC
                    </th >
                    <td></td>
                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                </tr >
                <tr>
                    <td colSpan={4} className="table-border-right"></td>
                    <th colSpan={2} >
                        Union Thanco Officer(UTO)
                    </th >
                    <td></td>
                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                </tr >
                <tr>
                    <td colSpan={4} className="table-border-right"></td>
                    <th colSpan={2} >
                        Advance
                    </th >
                    <td></td>
                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                </tr >
                <tr>
                    <td colSpan={4} className="table-border-right"></td>
                    <th colSpan={2} >
                        Income Tax
                    </th > <td></td>
                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                </tr >
                <tr className="myBackground">
                    <th colSpan={3}>
                        Total Payments
                    </th>
                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                    <th colSpan={3} >
                        Total Deductions
                    </th >
                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                </tr >
                <tr style={{ height: "40px" }}>
                    <th colSpan={2}>
                        Projection for Financial Year:
                    </th>
                    <th>
                    </th>
                    <td className="table-border-right">
                    </td>
                    <th colSpan={2} className="table-border-bottom" >
                        Net Salary
                    </th >
                    <td >
                    </td>
                    <td >
                    <input defaultValue={selectedEmp.salary} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                </tr >
                <tr>
                    <td colSpan={2}>
                        Gross Salary
                    </td> <td></td>
                    <td className="myAlign">
                    <input defaultValue={selectedEmp.salary} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td><td colSpan={4}></td>
                </tr >
                <tr>
                    <td colSpan={2}>
                        Overtime Payment (+)
                    </td> <td></td>
                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                    <th colSpan={2} >
                        {/* Delay Time Pay */}
                    </th >
                    <td colSpan={2}></td>
                </tr >
                <tr>
                    <td colSpan={2}>
                    Delay Time Payment (-)
                    </td> <td></td>
                    <td className="myAlign">
                    <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                    <td colSpan={2} >
                        {/* Empl PF Contribution */}
                    </td > <td></td>
                    <td className="myAlign">
                    {/* <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/> */}
                    </td>
                </tr >
                <tr>
                    <td colSpan={2}>
                        Extra Leavs Payment (-)
                    </td> <td></td>
                    <td className="myAlign">
                        <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td><td colSpan={4}></td>
                </tr >
                <tr>
                    <td colSpan={2}>
                        Total Income
                    </td> <td></td>
                    <td className="myAlign">
                        <input defaultValue={"00.00"} style={{border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/>
                    </td>
                    <td colSpan={4}></td>
                </tr >
                <tbody className="border-center">
                    <tr>
                        <th>
                            Attend/ Absence
                        </th>
                        <th>
                            Days in Month
                        </th>
                        <th>
                            Days Paid
                        </th>
                        <th>
                            Days Not Paid
                        </th>
                        <th>
                            Leave Position
                        </th>
                        <th>
                            Privilege Leave
                        </th>
                        <th>
                            Sick Leave
                        </th>
                        <th>
                            Casual Leave
                        </th>
                    </tr>                  
                    <tr>
                        <th >Current Month</th>
                        <td ><input defaultValue={"00.00"} style={{textAlign:"center", border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/></td>
                        <td ><input defaultValue={"00.00"} style={{textAlign:"center", border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/></td>
                        <td ><input defaultValue={"00.00"} style={{textAlign:"center", border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/></td>
                        <td><input defaultValue={"00.00"} style={{textAlign:"center", border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/></td>
                        <td><input defaultValue={"00.00"} style={{textAlign:"center", border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/></td> 
                        <td><input defaultValue={"00.00"} style={{textAlign:"center", border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/></td>
                        <td><input defaultValue={"00.00"} style={{textAlign:"center", border:"none",fontWeight:"500",fontSize:"17px",width:"150px"}}/></td>
                    </tr >                    
                </tbody>
            </table >

        </div >
        <div style={{width:"100%",textAlign:"center",padding:"0% 40%"}}>
        <ButtonWithIcon background={'green'} buttonName={'Genrate Salary Slip'} onClick={() => { handleGeneratePdf() }} buttonLoading={false} />        
        {/* <ButtonWithBackground buttonName={'Genrate Salary Slip'} onClick={() => { handleGeneratePdf() }} buttonLoading={false} />         */}
        </div>
    </div>
    )
}
