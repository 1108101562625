import React, { lazy, useEffect } from "react";
import Loading_Page from "./Neet-Components/Loading-Page/Loading_Page";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import Signup from "./Pages/SignupPage/Signup";
import { HighOrderFunctionWithParams } from "./Utils/HighOrderFunction";
import Dashboard from "./Pages/Dashboard/Dashboard";
import ErrorPage from "./Pages/errorScreen/ErrorPage";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import GuestLogin from "./Pages/guestUserLogin/GuestLogin";
const Login = lazy(() => import("./Pages/LoginPage/Login"));
const App = () => {
  useEffect(()=>{

  },[])
  return (
    <div>
      <React.Suspense fallback={<Loading_Page />}>        
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<HighOrderFunctionWithParams Component={Login}/>} />            
            {/* <Route path="/guest" element={<HighOrderFunctionWithParams Component={GuestLogin}/>} />             */}
            {/* <Route path="/forgot_password" element={<HighOrderFunctionWithParams Component={ForgotPassword}/>} />             */}
            {/* <Route path="/reset-password" element={<HighOrderFunctionWithParams Component={ResetPassword}/>} /> */}
            {/* <Route path="/signup" element={<HighOrderFunctionWithParams Component={Signup}/>} />             */}
            <Route path="/dashboard" element={<HighOrderFunctionWithParams Component={Dashboard}/>} />            
            <Route path="*" element={<ErrorPage/>}/>
          </Routes>
        </BrowserRouter>        
      </React.Suspense>
    </div>
  );
};

export default App;
