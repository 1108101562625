import React, { Component } from "react";
import style from "./settings.module.css";
import ToggleButtons from "../../Neet-Components/Toggle_Buttons/ToggleButtons";
import Change_Password from "../../Small-Components/change_password/Change_Password";
import Account_Details from "../../Small-Components/Account_details/Account_Details";
import { connect } from "react-redux";

interface SettingsState {
  isShowPaymentToggleButton:boolean;
}
interface SettingsProps {
  showPaymentScreen: boolean;
  screenType: any;
  handleNavChnage:Function;  
}
enum screenTypeSettings {
  ACCOUNT = "account",
  PAYMENT = "payment",
  PASSWORD = "password",
}
export  class Settings extends Component<SettingsProps, SettingsState> {
  constructor(props: SettingsProps) {
    super(props);
    this.state = {
      isShowPaymentToggleButton:false,
    };
  }  
  onToggleButtonClicked = (screenType: string) => {
    this.props.handleNavChnage(screenType);
  };
  onChangePasswordBackButtonClicked=async()=>{
    this.props.handleNavChnage(screenTypeSettings.ACCOUNT);
  }
  render() {
    console.log(this.props.showPaymentScreen)
    console.log(this.props.screenType)
    const { screenType } = this.props;
    const { isShowPaymentToggleButton } = this.state;
    return (
      <div className={style.setting}>
        <div className={style.toggle_buttons}>
          <ToggleButtons
            buttonId="1"
            buttonName="Account"
            onClick={() => {
              this.onToggleButtonClicked(screenTypeSettings.ACCOUNT);
            }}
            isSelected={
              screenType === screenTypeSettings.ACCOUNT ? true : false
            }
          />          
          <ToggleButtons
            buttonId="3"
            buttonName="Password"
            onClick={() => {
              this.onToggleButtonClicked(screenTypeSettings.PASSWORD);
            }}
            isSelected={
              screenType === screenTypeSettings.PASSWORD ? true : false
            }
          />
        </div>
        <div className={style.show_screen} style={screenType === screenTypeSettings.ACCOUNT || screenType === screenTypeSettings.PAYMENT?{overflowY:"scroll"}:{}}>
          {screenType === screenTypeSettings.PASSWORD && <Change_Password onChangePasswordBackButtonClicked={this.onChangePasswordBackButtonClicked}/>}          
          {screenType === screenTypeSettings.ACCOUNT && <Account_Details customer={{firstName:"",lastName:"",companyName:"",_id:"1234"}} setCustomerDetails={()=>{}} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);