import React, { Component } from "react";
import style from "./callLogs.module.css";
import { connect } from "react-redux";
import { Button, Skeleton } from "@mui/material";
import Loading_Page from "../../Neet-Components/Loading-Page/Loading_Page";
import CallLogsLayout from "../../Small-Components/Call_LogsLayout/CallLogsLayout";
import ApiClient from "../../Api/apiClient";
import EditAttandance from "../Attendance/UpdateAttendance";
import HolidayPopup from "./HolidayPopup";

export interface QueueData {
  createdAt: string;
  freeTime: number;
  isDefault: boolean;
  description: string;
  isPublic: boolean;
  loggedInUserOnly: boolean;
  maxSkipBeforeRemoveFromQueue: number;
  name: string;
  orgId: string;
  pricePerMin: number;
  queuePositionAfterWaitTime: string;
  queueSize: number;
  updatedAt: string;
  usersToNotifyOnAgentJoined: string[];
  waitBeforeSkip: number;
  __v: number;
  _id: string;
  noOfAgents: number;  
}
export interface PunchRecord {
  _id: string;
  employeeId: string;  
  punchInTime: string;
  punchInLocation: string;  
  punchOutTime: string;
  punchOutLocation: string;  
  isOnLeaves:boolean;
  isRequestForLeave: boolean;
  leaveRequestTime: null | any; // You might want to replace `any` with the specific type if known
  isLeaveApproved: boolean;
  leaveResion: null | string; // You might want to replace `string` with the specific type if known
  holidayResion: null | string; // You might want to replace `string` with the specific type if known
  createdAt: string;
  isWeekend:boolean;
  isHoliday:boolean;
  updatedAt: string;
  isLeaveDenied:boolean;
  __v: number;
}

interface CallLogsState {
  queueDetails: Array<QueueData>;
  loading: string;
  pageLoading: any;
  canJoinPaidQueue:boolean;
  attendaceRecords: Array<PunchRecord>;
  allEmployeeDetails:any;
  seleteAttendaceToEdit: any,
  isShowEditScreen: boolean,
  isShowHolidayPopup:boolean,
  holidayResion:string;
}
interface CallLogsProps {
  
  onJoinQueueButtonPressed: Function;
  
}
export class CallLogs extends Component<
  CallLogsProps,
  CallLogsState
> {
  customerDetails?: any;
  timeout?: any;
  canJoinPaidQueue?: any;
  constructor(props: CallLogsProps) {
    super(props);
    this.state = {
      queueDetails: [],
      loading: "",
      pageLoading: false,
      canJoinPaidQueue:false,
      attendaceRecords: [],
      allEmployeeDetails:[],
      seleteAttendaceToEdit: {},
      isShowEditScreen: false,
      isShowHolidayPopup:false,
      holidayResion:""
    };
  }
  async componentDidMount(): Promise<void> {
   this.getAllAttendanceToday();
  };

  getAllAttendanceToday=async()=>{
    this.setState({
      pageLoading:true
    })
    const res = await ApiClient.getInstance().get("/api/attandance/getAllToday");    
    const empRes = await ApiClient.getInstance().get("/api/employee/get");    
    console.log(res);
    console.log(empRes);
    // return;
    if (res.isSuccess && empRes.isSuccess) {
      this.setState({ pageLoading: false });
      this.setState({ attendaceRecords: res.data,allEmployeeDetails:empRes.data });
    } else {
      this.setState({ pageLoading: false });
    }
  }
  componentWillUnmount(): void {
    
  }  
  onEditCloseButtonPressed = (isRefresh:boolean) => {
    if(isRefresh){
      this.getAllAttendanceToday();
    }
    this.setState({ isShowEditScreen: false });
  }
  onEditButtonPressed = (seleteAttendaceToEdit: any) => {
    this.setState({ seleteAttendaceToEdit: seleteAttendaceToEdit, isShowEditScreen: true });
  }
  onHolidayCloseButtonPressed = (isRefresh:boolean) => {    
    this.setState({isShowHolidayPopup:false})
  }
  makeHolidayForAll=async()=>{
    if(this.state.holidayResion===""){
      alert("Please Provide a holiday resion");
      return;
    }
    this.setState({
      loading:"holiday"
    })
    const res = await ApiClient.getInstance().post("/api/attandance/makeHoliday",{holidayResion:this.state.holidayResion});        
    console.log(res);
    if (res.isSuccess) {
      this.getAllAttendanceToday();
      this.setState({
        loading:""
      })      
      this.setState({isShowHolidayPopup:false})
      alert("Holiday has been declared");
    } else {
      this.setState({
        loading:""
      })
      this.setState({isShowHolidayPopup:false})
      alert("Something went wrong");
    }
  }
  showHolidayPopup=()=>{
    this.setState({
      isShowHolidayPopup:!this.state.isShowHolidayPopup
    })
  }
  onUpdate=(holidayResion:string)=>{
    this.setState({holidayResion:holidayResion});
  }  
  render() {
    const { queueDetails, loading,pageLoading,canJoinPaidQueue } = this.state;
    if(pageLoading){
      return <Loading_Page/>
    }
else
    return (
      <div
        className={
          loading === "queue" && queueDetails.length <= 0
            ? style.queue_details_loading
            : style.queue_details
        }
      >
      {this.state.isShowEditScreen && <div style={{ position: "absolute", top: '0px', left: "0", height: "100vh", width: "100vw", background: "rgb(105 105 105 / 51%)", display: "flex", alignItems: "center", justifyContent: "center", zIndex: "100" }}> <EditAttandance attandanceToEdit={this.state.seleteAttendaceToEdit} onCloseEditModelClicked={this.onEditCloseButtonPressed} /></div>}
      {this.state.isShowHolidayPopup && <div style={{ position: "absolute", top: '0px', left: "0", height: "100vh", width: "100vw", background: "rgb(105 105 105 / 51%)", display: "flex", alignItems: "center", justifyContent: "center", zIndex: "100" }}> <HolidayPopup loading={this.state.loading} onUpdate={this.onUpdate} onHolidayCloseButtonPressed={this.onHolidayCloseButtonPressed}  makeHolidayForAll={this.makeHolidayForAll}/></div>}
        <div style={{width:"95%",alignItems:"flex-end",justifyContent:"flex-end",margin:"40px 0px",display:"flex"}}>
        <Button
                sx={{
                  height: "40px",
                  textDecoration: "none!important",
                  textTransform:"none",
                  fontSize: "16px",
                  color: "white",
                  width:"200px",
                }}
                variant="contained"
                // disabled
                onClick={()=>{this.getAllAttendanceToday();}}
              >
                Refresh Request
              </Button>
              <Button
                sx={{
                  height: "40px",
                  textDecoration: "none!important",
                  textTransform:"none",
                  fontSize: "16px",
                  color: "white",
                  width:"200px",
                  marginLeft:"10px",
                  background:"red"
                }}
                variant="contained"
                // disabled
                onClick={this.showHolidayPopup}
              >
                {loading==="holiday"?"Loading...":"Declare Holiday"}
              </Button>
              </div>
        {false && queueDetails.length <= 0 ? (
          <>
            <Skeleton className={style.queue_loading} height={20} />
            <Skeleton className={style.queue_loading} />
            <Skeleton className={style.queue_loading} />
            {/* <Skeleton className={style.queue_loading} /> */}
          </>
        ) : 
        <div style={{maxHeight:"800px",padding:"10px",overflowY:"scroll",maxWidth:"100%",margin:0,overflowX:"hidden",display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
          {
            this.state.attendaceRecords.map((data:PunchRecord) => {
            return (
              <CallLogsLayout
              attendaceRecords={data}
              empData={this.state.allEmployeeDetails}
              onEditButtonPressed={this.onEditButtonPressed}
                // onJoinQueueButtonPressed={this.props.onJoinQueueButtonPressed}
                // canJoinPaidQueue={canJoinPaidQueue}
              />
            ) 
          })        
        }
         </div>
      }      
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({  
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CallLogs);
