import React, { Component } from "react";
import style from "./leaveApprovalLayout.module.css";
import { Avatar, Button } from "@mui/material";
import { connect } from "react-redux";
import Loading_Page from "../../Neet-Components/Loading-Page/Loading_Page";
import ApiClient from "../../Api/apiClient";
import moment from "moment";

interface leaveApprovalLayoutProps {
  requests: any;
  allEmp: any;

}
interface leaveApprovalLayoutState {
  loading: string;
  matchedEmployee: any;
  isApproved: boolean,
  isDenied: boolean,
}
export class leaveApprovalLayout extends Component<
  leaveApprovalLayoutProps,
  leaveApprovalLayoutState
> {
  canJoinPaidQueue?: boolean;
  constructor(props: leaveApprovalLayoutProps) {
    super(props);
    this.state = {
      loading: "pageLoading",
      matchedEmployee: null,
      isApproved: this.props.requests.isLeaveApproved,
      isDenied: this.props.requests.isLeaveDenied,
    };
  }
  componentDidMount(): void {
    const matchedEmp = this.props.allEmp.filter((data: any) => {
      return data._id == this.props.requests.employeeId;
    });
    console.log(this.props.allEmp)
    this.setState({ matchedEmployee: matchedEmp, loading: '' });
    console.log(matchedEmp);
  }

  onLeaveApprovedButtonPressed = async () => {
    this.setState({
      loading: 'approveLoading'
    })
    const res = await ApiClient.getInstance().post("/api/leave/update", { _id: this.props.requests._id, isLeaveApproved: true, employeeId: this.state.matchedEmployee[0]._id, });
    console.log(res);
    if (res.data.isSuccess) {
      this.setState({ loading: '', isApproved: true });
    } else {
      this.setState({ loading: '' });
      alert("something went wrong! please try again")
    }
  }
  onLeaveRejectButtonPressed = async () => {
    this.setState({
      loading: 'RejectLoading'
    })
    const res = await ApiClient.getInstance().post("/api/leave/update", { _id: this.props.requests._id, isLeaveDenied: true, employeeId: this.state.matchedEmployee[0]._id, });
    console.log(res);
    if (res.data.isSuccess) {
      this.setState({ loading: '', isDenied: true });
    } else {
      this.setState({ loading: '' });
      alert("something went wrong! please try again")
    }
  }

  render() {
    const { loading, matchedEmployee, isApproved, isDenied } = this.state;
    if (loading == 'pageLoading') {
      return <Loading_Page />
    }
    return (
      <div className={style.CallLogsLayout} style={{ height: "auto", padding: "10px" }}>
        <div className={style.CallLogsLayout_avatar}>
          <Avatar />
          {/* <p className={style.queue_status_online}>Caller Img</p> */}
          <div className={style.CallLogsLayout_details}>
            <p className={style.CallLogsLayout_header}>{matchedEmployee[0].employeeId}</p>
            <p className={style.CallLogsLayout_title}>
              {matchedEmployee[0].firstName + " " + matchedEmployee[0].lastName}
            </p>
          </div>
        </div>
        <div className={style.CallLogsLayout_timers}>
          <div className={style.CallLogsLayout_stats}>
            <p className={style.CallLogsLayout_stats_heading} style={{ fontWeight: "700" }}>
              Reason for Leave
            </p>
            <p className={style.CallLogsLayout_stats_heading}>
              {this.props.requests.resionForLeave ? this.props.requests.resionForLeave : "No Reason Provided"}
            </p>
          </div>
          <div className={style.CallLogsLayout_stats}>
            <p className={style.CallLogsLayout_stats_heading} style={{ fontWeight: "700" }}>
              Days For Leave
            </p>
            <p className={style.CallLogsLayout_stats_heading} style={{ display: "flex", flexDirection: "column", gap: "3px" }}>
              {this.props.requests?.daysForLeave ? this.props.requests.daysForLeave.map((days: string) => {
                return <p style={{ margin: "0px" }}>{moment(+days).format('MM/DD/YY')}</p>
              }) : "No Days"}
            </p>
          </div>
          {/* <div className={style.CallLogsLayout_stats}>
            <p className={style.CallLogsLayout_stats_heading}>
              5m Wait
            </p>
            <img style={{ height: "14px",width:"15.5px" }} src={timer} />
          </div>
          <div className={style.CallLogsLayout_stats}>
            <p className={style.CallLogsLayout_stats_heading}>
              Free
            </p>
            <img style={{ height: "16px",width:"16px" }} src={pricePerMin} />
          </div> */}
        </div>

        {isApproved == false && isDenied == false && (
          <div className={style.buttons}>
            <Button
              sx={{
                height: "40px",
                textDecoration: "none!important",
                textTransform: "none",
                fontSize: "16px",
                color: "white",
                width: "85px",
                background: "green",
              }}
              variant="contained"
              onClick={this.onLeaveApprovedButtonPressed}
            // disabled                
            >
              {this.state.loading == 'approveLoading' ? "loading...." : "Approve"}
            </Button>
            <Button
              sx={{
                height: "40px",
                textDecoration: "none!important",
                textTransform: "none",
                fontSize: "16px",
                color: "white",
                width: "85px",
                backgroundColor: "red"
              }}
              variant="contained"
              onClick={this.onLeaveRejectButtonPressed}
            // disabled
            >
              {this.state.loading == 'RejectLoading' ? "loading...." : "Reject"}

            </Button>
          </div>
        )}
        {isApproved == true && isDenied == false && <p style={{ color: "green" }}>Approved</p>}
        {isDenied == true && isApproved == false && <p style={{ color: "red" }}>Rejected</p>}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(leaveApprovalLayout);
