import React from "react";
import style from "./button.module.css";
import { CircularProgress } from "@mui/material";

const ButtonWithBackground = ({ buttonName, onClick, buttonLoading }: any) => {
  return (
    <button onClick={onClick} className={style.button_with_background}>
      {buttonLoading ? (
        <CircularProgress
          sx={{
            color: "white",
            height: "20px!important",
            width: "20px!important",
          }}
        />
      ) : (
        buttonName
      )}
    </button>
  );
};

export default ButtonWithBackground;
