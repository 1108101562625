import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ApiClient from "../../Api/apiClient";

export default function AddAgent() {
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    employeeId: "",
    role: "",
    password: "",
    salary: "",
    shiftStartTime: "",
    shiftEndTime: "",
    leavePerMonth: "",
    confirm_password: "",
    joiningDate: "",
    bankAcNumber: "",
    panNo: "",
    location: ""

  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    const apiData = {
      firstName: event.target.firstName.value,
      lastName: event.target.lastName.value,
      employeeId: event.target.employeeId.value,
      role: event.target.role.value,
      password: event.target.password.value,
      salary: event.target.salary.value,
      shiftStartTime: event.target.shiftStartTime.value,
      shiftEndTime: event.target.shiftEndTime.value,
      leavePerMonth: event.target.leavePerMonth.value,
      confirm_password: event.target.confirm_password.value,
      joiningDate: event.target.joiningDate.value,
      bankAcNumber: event.target.bankAcNumber.value,
      panNo: event.target.panNo.value,
      location: event.target.location.value,
    };

    try {
      const response = await ApiClient.getInstance().post(
        "/api/employee/register",
        apiData
      );

      console.log(response);

      if (response.data.statusCode === 200) {
        alert("Employee registration successfully registered");
        // Reset form data after successful API call
        setFormData({
          firstName: "",
          lastName: "",
          employeeId: "",
          role: "",
          password: "",
          salary: "",
          shiftStartTime: "",
          shiftEndTime: "",
          leavePerMonth: "",
          confirm_password: "",
          joiningDate: "",
          bankAcNumber: "",
          panNo: "",
          location: ""

        });

        // Optionally, you can handle success actions here
      } else {
        alert(response.data.message || "Unknown error");
      }

      setLoading(false);
    } catch (error: any) {
      console.error("Error during API call:", error);
      alert(error.message || "Unknown error");
      setLoading(false);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <PersonIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Add Employee
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="employeeId"
                label="Employee ID"
                name="employeeId"
                onChange={handleChange}
                value={formData.employeeId}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                onChange={handleChange}
                value={formData.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="lastName"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                autoFocus
                onChange={handleChange}
                value={formData.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="role"
                label="Employee Role"
                name="role"
                onChange={handleChange}
                value={formData.role}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={handleChange}
                value={formData.password}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="confirm_password"
                label="Confirm Password"
                type="password"
                id="confirm_password"
                onChange={handleChange}
                value={formData.confirm_password}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                type="time"
                onTimeUpdate={(e) => { console.log(e) }}
                id="shiftStartTime"
                label="Shift Start Time"
                name="shiftStartTime"
                onChange={handleChange}
                value={formData.shiftStartTime}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                type="time"
                id="shiftEndTime"
                label="Shift End Time"
                name="shiftEndTime"
                onChange={handleChange}
                value={formData.shiftEndTime}
              />
            </Grid>



            {/* new models */}

            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                type="date"
                onTimeUpdate={(e) => { console.log(e) }}
                id="joiningDate"
                label="Joining Date"
                name="joiningDate"
                onChange={handleChange}
                value={formData.joiningDate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                type="text"
                id="bankAcNumber"
                label="Bank A/c No."
                name="bankAcNumber"
                onChange={handleChange}
                value={formData.bankAcNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                type="text"
                onTimeUpdate={(e) => { console.log(e) }}
                id="panNo"
                label="Pan No."
                name="panNo"
                onChange={handleChange}
                value={formData.panNo}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="leavePerMonth"
                label="Leave Per Month"
                id="leavePerMonth"
                onChange={handleChange}
                value={formData.leavePerMonth}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="salary"
                required
                fullWidth
                id="salary"
                label="Employee Salary"
                autoFocus
                onChange={handleChange}
                value={formData.salary}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="location"
                label="Employee Location"
                id="location"
                onChange={handleChange}
                value={formData.location}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? "Loading..." : "Add Employee"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
