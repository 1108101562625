import React from "react";
import style from "./button.module.css";
import { CircularProgress } from "@mui/material";

const HeaderMenuButton = ({ buttonName, onClick, buttonLoading,iconName ,color,isSelected}: any) => {
  return (
    <button onClick={onClick} className={isSelected ? style.header_menu_button_selected:style.header_menu_button} style={{color:color}}>
    {iconName && iconName}
      {buttonLoading ? (
        <CircularProgress
          sx={{
            color: color,
            height: "20px!important",
            width: "20px!important",
          }}
        />
      ) : (
        buttonName
      )}
    </button>
  );
};

export default HeaderMenuButton;
