import React, { Component } from "react";
import style from "./leaveApproval.module.css";
import { connect } from "react-redux";
import { Button, Skeleton } from "@mui/material";
import Loading_Page from "../../Neet-Components/Loading-Page/Loading_Page";
import LeaveApprovalLayout from "../../Small-Components/Leave_Approval/LeaveApprovalLayout";
import ApiClient from "../../Api/apiClient";


interface LeaveApprovalState {
  loading: string;
  pageLoading: any;
  requests:any;
  allEmployeeDetails:any;
}
interface LeaveApprovalProps {
  onJoinQueueButtonPressed: Function;
}
export class LeaveApproval extends Component<
  LeaveApprovalProps,
  LeaveApprovalState
> {
  customerDetails?: any;
  timeout?: any;
  canJoinPaidQueue?: any;
  constructor(props: LeaveApprovalProps) {
    super(props);
    this.state = {      
      loading: "",
      pageLoading: false,
      requests:[],
      allEmployeeDetails:[]
    };
  }
  async componentDidMount(): Promise<void> {
    this.getAllAttendanceToday();
   };
 
   getAllAttendanceToday=async()=>{
     this.setState({
       pageLoading:true
     })
     const res = await ApiClient.getInstance().get("/api/leave/get");    
     const empRes = await ApiClient.getInstance().get("/api/employee/get");    
     console.log(res);
     if (res.isSuccess && empRes.isSuccess) {
       this.setState({ pageLoading: false });
       this.setState({ requests: res.data,allEmployeeDetails:empRes.data });
     } else {
       this.setState({ pageLoading: false });
     }
   }
   componentWillUnmount(): void {
     
   }    
  render() {
    const { loading,pageLoading ,requests} = this.state;
    if(pageLoading){
      return <Loading_Page/>
    }
else
    return (
      <div
        className={
          loading === "queue" && requests.length <= 0
            ? style.queue_details_loading
            : style.queue_details
        }
      >
        <div style={{width:"95%",alignItems:"flex-end",justifyContent:"flex-end",margin:"40px 0px",display:"flex",}}>
        <Button
                sx={{
                  height: "40px",
                  textDecoration: "none!important",
                  textTransform:"none",
                  fontSize: "16px",
                  color: "white",
                  width:"200px",
                }}
                variant="contained"
                onClick={()=>{this.getAllAttendanceToday();}}
                // disabled
              >
                Refresh Request
              </Button>
              </div>
        {false && requests.length <= 0 ? (
          <>
            <Skeleton className={style.queue_loading} height={20} />
            <Skeleton className={style.queue_loading} />
            <Skeleton className={style.queue_loading} />
            {/* <Skeleton className={style.queue_loading} /> */}
          </>
        ) : (requests.length>0?
          requests.map((data:any) => {
            return (
              <LeaveApprovalLayout
              requests={data}
                allEmp={this.state.allEmployeeDetails}
                // onJoinQueueButtonPressed={this.props.onJoinQueueButtonPressed}
                // canJoinPaidQueue={canJoinPaidQueue}
              />
            ) 
          })
        :"No Data Found")
      }
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({  
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LeaveApproval);
