import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ApiClient from "../../Api/apiClient";
import { Close } from "@mui/icons-material";

export default function EditAgent({ empToEdit, onCloseEditModelClicked }: any) {
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = useState({
    firstName: empToEdit.firstName,
    lastName: empToEdit.lastName,
    employeeId: empToEdit.employeeId,
    role: empToEdit.role,
    salary: empToEdit.salary,
    shiftStartTime: empToEdit.shiftStartTime,
    shiftEndTime: empToEdit.shiftEndTime,
    leavePerMonth: empToEdit.leavePerMonth,
    joiningDate: empToEdit?.joiningDate ? empToEdit.joiningDate : "",
    bankAcNumber: empToEdit?.bankAcNumber ? empToEdit.bankAcNumber : "",
    panNo: empToEdit?.panNo ? empToEdit.panNo : "",
    location: empToEdit?.location ? empToEdit.location : "",
  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const apiData = {
      _id: empToEdit._id,
      firstName: event.target.firstName.value,
      lastName: event.target.lastName.value,
      role: event.target.role.value,
      salary: event.target.salary.value,
      shiftStartTime: event.target.shiftStartTime.value,
      shiftEndTime: event.target.shiftEndTime.value,
      leavePerMonth: event.target.leavePerMonth.value,
      joiningDate: event.target.joiningDate.value,
      bankAcNumber: event.target.bankAcNumber.value,
      panNo: event.target.panNo.value,
      location: event.target.location.value,
    };
    try {
      const response = await ApiClient.getInstance().post(
        "/api/employee/update",
        apiData
      );

      console.log(response);

      if (response.data.statusCode === 200) {
        alert("Employee updated successfully");
        onCloseEditModelClicked(true);
        // Reset form data after successful API call
        setFormData({
          firstName: "",
          lastName: "",
          employeeId: "",
          role: "",
          salary: "",
          shiftStartTime: "",
          shiftEndTime: "",
          leavePerMonth: "",
          joiningDate: "",
          bankAcNumber: "",
          panNo: "",
          location: ""
        });

        // Optionally, you can handle success actions here
      } else {
        alert(response.data.message || "Unknown error");
      }
      setLoading(false);
    } catch (error: any) {
      console.error("Error during API call:", error);
      alert(error.message || "Unknown error");
      setLoading(false);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <Container component="main" maxWidth="xs" style={{ background: "#fff", borderRadius: "10px", margin: "20px" }}>
      <div style={{ width: "100%", display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
        <div onClick={onCloseEditModelClicked} style={{ marginTop: "20px", padding: "10px", cursor: "pointer", background: "lightgray", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Close />
        </div>
      </div>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <PersonIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Edit Employee
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="employeeId"
                label="Employee ID"
                name="employeeId"
                onChange={handleChange}
                value={formData.employeeId}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                onChange={handleChange}
                value={formData.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="lastName"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                autoFocus
                onChange={handleChange}
                value={formData.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="role"
                label="Employee Role"
                name="role"
                onChange={handleChange}
                value={formData.role}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                type="time"
                onTimeUpdate={(e) => { console.log(e) }}
                id="shiftStartTime"
                label="Shift Start Time"
                name="shiftStartTime"
                onChange={handleChange}
                value={formData.shiftStartTime}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                type="time"
                id="shiftEndTime"
                label="Shift End Time"
                name="shiftEndTime"
                onChange={handleChange}
                value={formData.shiftEndTime}
              />

            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                type="date"
                onTimeUpdate={(e) => { console.log(e) }}
                id="joiningDate"
                label="Joining Date"
                name="joiningDate"
                onChange={handleChange}
                value={formData.joiningDate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                type="text"
                id="bankAcNumber"
                label="Bank A/c No."
                name="bankAcNumber"
                onChange={handleChange}
                value={formData.bankAcNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                type="text"
                onTimeUpdate={(e) => { console.log(e) }}
                id="panNo"
                label="Pan No."
                name="panNo"
                onChange={handleChange}
                value={formData.panNo}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="leavePerMonth"
                label="Leave Per Month"
                id="leavePerMonth"
                onChange={handleChange}
                value={formData.leavePerMonth}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="salary"
                required
                fullWidth
                id="salary"
                label="Employee Salary"
                autoFocus
                onChange={handleChange}
                value={formData.salary}
              />

            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="location"
                label="Employee Location"
                id="location"
                onChange={handleChange}
                value={formData.location}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? "Loading..." : "Update Employee"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
