import React, { Component } from "react";
import style from "./dashboard.module.css";
import Header from "../../Components/Header/Header";
import { Button } from "@mui/material";
import Settings from "../../Components/Settings/Settings";
import { connect } from "react-redux";
import Loading_Page from "../../Neet-Components/Loading-Page/Loading_Page";
import SettingGryaIcon from "../../Assets/icons/settingNormalIcon.png";
import SettingColorIcon from "../../Assets/icons/settingColorIcon.png";
import HistoryColorIcon from "../../Assets/icons/historyColorIcon.png";
import HistoryGrayIcon from "../../Assets/icons/historyNormalIcon.png";
import { ScreenTypeDashboard, screenTypeSettings } from "../../Utils/eums";
import AddManagers from "../../Components/Add_Managers/AddManagers";
import AddCategory from "../../Components/Add_Categorys/AddCategory";
import AddAgent from "../../Components/Add_Agents/Add_Agents";
import Meet_History from "../../Components/Meet_History/Meet_History";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UserStatus from "../../Utils/userStatus";
import OneEmpAttendance from "../../Components/Attendance/OneEmpAttendance";
import CallLogs from "../../Components/Call_Logs/CallLogs";
import LeaveApproval from "../../Components/Leave_Approval/LeaveApproval";
import Update_Password from "../../Components/Add_Agents/Update_Password";
import Salary_Slip_Genrator from "../../Components/Salary_Slip/Salary_Slip_Genrator";

interface DashboardState {
  screenType: string;
  goToPasswordScreen: boolean;
  pageLoading: boolean;
  screenNav: any;
  customerSeatDetails: any;
  logOutLoading: boolean;
  onMenuButtonHover: string;
  empId: string;
  adminDetails: any
  empName:string;
}
interface DashboardProps {
  customer: any;
  organization: any;
  setCustomerDetails: Function;
  setCustomerToken: Function;
  customerToken: string;
  params: any;
  navigation: Function;
}
const buttonData = [
  {
    type: ScreenTypeDashboard.ATTENDANCE_TODAY,
    label: 'Attendance',
    icon: SettingGryaIcon,
    selectedIcon: SettingColorIcon,
    condition: true,
  },
  {
    type: ScreenTypeDashboard.MEETING_HISTORY,
    label: 'Employees',
    icon: SettingGryaIcon,
    selectedIcon: SettingColorIcon,
    condition: true,
  },
  {
    type: ScreenTypeDashboard.LEAVE_APPROVAL,
    label: 'Leave Request',
    icon: HistoryGrayIcon,
    selectedIcon: HistoryColorIcon,
  },
  // {
  //   type: ScreenTypeDashboard.ADD_CATEGORY,
  //   label: 'Add Categorys',
  //   icon: SettingGryaIcon,
  //   selectedIcon: SettingColorIcon,
  //   condition: true,
  // },
  {
    type: ScreenTypeDashboard.ADD_AGENTS,
    label: 'Add Employees',
    icon: SettingGryaIcon,
    selectedIcon: SettingColorIcon,
    condition: true,
  },
  {
    type: ScreenTypeDashboard.UPDATE_EMP_PASSWORD,
    label: 'Update Emp Password',
    icon: SettingGryaIcon,
    selectedIcon: SettingColorIcon,
    condition: true,
  },
  {
    type: ScreenTypeDashboard.SALARY_SLIP_GENRATE,
    label: 'Salary Slip Genrate',
    icon: SettingGryaIcon,
    selectedIcon: SettingColorIcon,
    condition: true,
  },
  {
    type: ScreenTypeDashboard.SETTINGS,
    label: 'Settings',
    icon: SettingGryaIcon,
    selectedIcon: SettingColorIcon,
    condition: true,
  },
];
export class Dashboard extends Component<DashboardProps, DashboardState> {
  queueForJoin?: any;
  directGoToQueue?: any;
  constructor(props: DashboardProps) {
    super(props);
    this.state = {
      screenType: ScreenTypeDashboard.ADD_AGENTS,
      goToPasswordScreen: false,
      pageLoading: true,
      screenNav: screenTypeSettings.ACCOUNT,
      customerSeatDetails: "",
      logOutLoading: false,
      onMenuButtonHover: ScreenTypeDashboard.NONE,
      empId: "",
      adminDetails: {},
      empName:"",
    };
  }
  async componentDidMount() {
    const data: any = localStorage.getItem("shivaayAdmin");
    const user = JSON.parse(data);
    if (!user?._id && !user?.name) {
      this.props.navigation("/login")
      window.location.reload();
    };
    this.setState({ pageLoading: false, adminDetails: user })
  }
  onMenuButtonClicked = (screenType: string) => {
    this.setState({
      screenType: screenType,
      goToPasswordScreen: false,
      screenNav: screenTypeSettings.ACCOUNT,
    });
  };
  onSettingButtonClicked = (screenType: string) => {
    this.setState({ screenType: screenType, goToPasswordScreen: false }, () => {
      this.setState({
        screenNav: this.state.goToPasswordScreen
          ? screenTypeSettings.PASSWORD
          : screenTypeSettings.ACCOUNT,
      });
    });
    console.log(screenType);
  };
  onHeaderChangePasswordButtionClicked = (screenType: string) => {
    this.setState({ screenType: screenType, goToPasswordScreen: true }, () => {
      this.setState({
        screenNav: this.state.goToPasswordScreen
          ? screenTypeSettings.PASSWORD
          : screenTypeSettings.ACCOUNT,
      });
    });
    console.log(screenType);
  };
  handleNavChnage = (screenType: string) => {
    this.setState({
      screenNav: screenType,
    });
  };
  onShowReceiptButtonClicked = (id: string,name:string) => {
    this.setState({ screenType: "AttendanceByEmp", empId: id ,empName:name})
  }
  render() {
    const { screenType, goToPasswordScreen, pageLoading, customerSeatDetails } =
      this.state;
    const { customer } = this.props;
    if (pageLoading) {
      return <Loading_Page />;
    } else {
      return (
        <div className={style.dashboard}>
          <Header
            screenType={this.state.screenNav}
            onLogoButtonClicked={() => {
              this.onMenuButtonClicked(ScreenTypeDashboard.ADD_AGENTS);
            }}
            onSettingButtonClicked={() => {
              this.onSettingButtonClicked(ScreenTypeDashboard.SETTINGS);
            }}
            onHeaderChangePasswordButtionClicked={() => {
              this.onHeaderChangePasswordButtionClicked(
                ScreenTypeDashboard.SETTINGS
              );
            }}
            organization={this.state.adminDetails.companyName}
            customer={this.state.adminDetails}
          />



          <div className={style.dashboard_container}>
            <div className={style.dashboard_menu}>
              {buttonData.map((button) => (
                button.condition === undefined || button.condition ? (
                  <Button
                    key={button.type}
                    variant="outlined"
                    startIcon={
                      <AddCircleIcon />
                      // <img
                      //   src={this.state.screenType === button.type || this.state.onMenuButtonHover === button.type
                      //     ? button.selectedIcon
                      //     : button.icon
                      //   }
                      //   height={"16px"}
                      //   width={"16px"}
                      // />
                    }
                    className={
                      this.state.screenType === button.type
                        ? style.dashboard_menu_button_selected
                        : style.dashboard_menu_button
                    }
                    onClick={() => {
                      this.onMenuButtonClicked(button.type);
                    }}
                    onMouseEnter={() => {
                      this.setState({
                        onMenuButtonHover: button.type,
                      });
                    }}
                    onMouseLeave={() => {
                      this.setState({
                        onMenuButtonHover: ScreenTypeDashboard.NONE,
                      });
                    }}
                  >
                    {button.label}
                  </Button>
                ) : null
              ))}
            </div>
            <div className={style.dashboard_screen}>
              {screenType === ScreenTypeDashboard.SETTINGS && (
                <Settings
                  showPaymentScreen={goToPasswordScreen}
                  screenType={this.state.screenNav}
                  handleNavChnage={this.handleNavChnage}
                />
              )}
              {/* {screenType === ScreenTypeDashboard.ADD_HOTELS && (
                <AddHotels/>
              )}               */}
              {screenType === ScreenTypeDashboard.ADD_MANAGERS && (
                <AddManagers />
              )}
              {screenType === ScreenTypeDashboard.ADD_CATEGORY && (
                <AddCategory />
              )}
              {screenType === ScreenTypeDashboard.ADD_AGENTS && (
                <AddAgent />
              )}
              {screenType === "AttendanceByEmp" && this.state.empId && (
                <OneEmpAttendance empId={this.state.empId} name={this.state.empName}/>
              )}
              {screenType === ScreenTypeDashboard.LEAVE_APPROVAL && (
                <LeaveApproval onJoinQueueButtonPressed={() => { }} />
              )}
              {screenType === ScreenTypeDashboard.ATTENDANCE_TODAY && (
                // <OneEmpAttendance empId={this.state.empId}/>
                <CallLogs onJoinQueueButtonPressed={() => { }} />
              )}
              {/* {screenType === ScreenTypeDashboard.CALL_LOGS && (
                <CallLogs onJoinQueueButtonPressed={()=>{}}/>
              )}               */}
              {screenType === ScreenTypeDashboard.UPDATE_EMP_PASSWORD && (
                <Update_Password/>
              )}
              {screenType === ScreenTypeDashboard.SALARY_SLIP_GENRATE && (
                <Salary_Slip_Genrator/>
              )}
              {screenType === ScreenTypeDashboard.MEETING_HISTORY && (
                <Meet_History onShowReceiptButtonClicked={this.onShowReceiptButtonClicked} />
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
