import React from "react";
import style from "./textFields.module.css";

const TextFieldSimple = ({
  onChange,
  placeholder,
  defaultValue,
  value,
  errorMsg,
}: any) => {
  return (
    <div className={style.text_field_simple_container}>
      <input
        className={style.text_field_simple}
        placeholder={placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
      />
      <p
        className={style.text_field_simple_error}
        style={
          errorMsg.length > 1
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
      >
        {errorMsg.length > 1 ? errorMsg : "A"}
      </p>
    </div>
  );
};

export default TextFieldSimple;
