import moment from "moment";

export default class GlobalHandler {
  static globalHandler: any = new GlobalHandler();
  static getInstance = () => {
    if (!GlobalHandler.globalHandler) {
      GlobalHandler.globalHandler = new GlobalHandler();
    }
    return GlobalHandler.globalHandler;
  };
  logOutAgent(): void {
    const orgAlias = this.getOrgAliasFromLocal();
    if (orgAlias) {
      localStorage.removeItem("agentData");
      localStorage.removeItem("_liveZapir_orgAlias_agent");
      document.cookie =
        "agentToken" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      window.location.href = `/agent/${orgAlias}`;
      console.log("logOut function called");
    } else {
      localStorage.removeItem("agentData");
      document.cookie =
        "agentToken" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      window.location.href = "/agent";
      console.log("logOut function called");
    }
  }

  validateEmail(email: string): boolean {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  getAgentDetails(): any {
    const retrieveData: string = localStorage.getItem("agentData") as string;
    const adminData = JSON.parse(retrieveData);
    if (adminData) {
      return adminData;
    }
  }

  getOrgAliasFromLocal(): string | false {
    const retrieveData: string = localStorage.getItem(
      "_liveZapir_orgAlias_agent"
    ) as string;
    const userData = JSON.parse(retrieveData);
    if (userData) {
      return userData.orgAlias;
    } else {
      return false;
    }
  }

  getCookie(n: any): string {
    let a = `; ${document.cookie}`.match(`;\\s*${n}=([^;]+)`);
    return a ? a[1] : "";
  }

  getAgentToken() {
    const token = this.getCookie("agentToken");
    if (token) {
      return token;
    }
  }

  colorConsoleSuccess(
    msg: string,
    background?: string,
    color?: string,
    subject?: string
  ): void {
    console.log(
      `%c ${subject ? subject : "no sub"} : ${msg ? msg : "no msg"}`,
      `color: ${color ? color : "black"}; background-color: ${
        background ? background : "green"
      }`
    );
  }
  clearUp = () => {
    GlobalHandler.globalHandler = undefined;
  };
}

export const calculateMeetingDuration = (a: any, b: any) => {
  if (a && b) {
    var diff = Math.abs(a - b);
    var ms = diff % 1000;
    diff = (diff - ms) / 1000;
    var ss = diff % 60;
    diff = (diff - ss) / 60;
    var mm = diff % 60;
    diff = (diff - mm) / 60;
    var hh = diff % 24;
    var days = (diff - hh) / 24;
    return mm + " min " + " : " + ss + " sec ";
  } else {
    return "---";
  }
};
export const convertTimeStampToDate = (timestamp: any) => {
  if (timestamp > 0) {
    const formattedTime = moment(timestamp).format("DD/MM/YY");
    return formattedTime;
  }
  return "---";
};
export const convertTimeStampToDateWithMonthName = (timestamp: any) => {
  if (timestamp > 0) {
    const formattedTime = moment(timestamp).format("MMMM DD YYYY");
    return formattedTime;
  }
  return "---";
};

export const convertTimeStampToHoursAndMin = (timestamp: any) => {
  if (timestamp > 0) {
    const formattedTime = moment(timestamp).format("hh:mm A");
    return formattedTime;
  }
  return "---";
};

export const getBaseUrlForLogo = () => {
  const url=process.env.NODE_ENV === "production"
    ?  process.env.REACT_APP_API_BASE_URL_PROD
    : process.env.REACT_APP_API_BASE_URL_LOCAL;
    return url;
};
