import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function PaginationControlled({pageSize,recordCount,pageNo,onNextOrPrevPageButtonClicked}:any) {
  const [page, setPage] = React.useState(pageNo+1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log(`Pagination`,value);
    setPage(value);
  onNextOrPrevPageButtonClicked(value);
  }
  return (
 <div className="pagination_mui" style={{display:"flex",justifyContent:"end",alignItems:"center",flexDirection:"row"}}>                
        <Stack spacing={2} >
        <p className="page_size_text">          
          Total Results : <span className="page_size_number">{recordCount}</span> Page No: {pageNo}
        </p>      
      <Pagination count={Math.ceil(recordCount/pageSize)} page={pageNo} onChange={handleChange}  style={{marginTop:"0px"}}/>
    </Stack>        
      </div>
  );
}